import { useContext, useMemo } from "react";
import FormFieldGroup from "../../../../../components/ui/objects/FormFieldGroup/FormField.Group";
import { debounce } from "lodash";
import { CodigoObraFieldContext } from "../../../context/ModalFields.Context";

const CodigoObraInput: React.FC<{idTest?:string}> = ({idTest}) => {

	const field = useContext(CodigoObraFieldContext);

	const debouncedSearch = useMemo(() => { 
		
		const baseTag = {
			label: "Cod. Obra",
			onClear: () => {},
		}

		function handleClear() {
			field.onClear();
			field.onFilter({ ...baseTag, text: undefined });
		}

		const handleFilterChange = (value: string) => {
			field.onSearch(value); 
			field.onFilter({...baseTag, text: value, onClear: handleClear})
		};

		return debounce((value: string) => handleFilterChange(value), 1000)
	}, [field]);

	const handleInputChange = (e: any) => {
		const value = e.target.value;
		
		debouncedSearch(value);
	}

	return (
		<div className='input-container'>
		<FormFieldGroup>
			<label> Código Obra</label>
			<input 
			// className="op-input form-control" 
				   placeholder="Cod. Obra"
				   onChange={handleInputChange}
				   data-cy={idTest}
			>

			</input>
		</FormFieldGroup>
		</div>
	)
}

export default CodigoObraInput;