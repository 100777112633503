import ApiRequest from "../../../../domain/ApiRequest";
import { UserPreferenceApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { UserPreferenceDto } from "../../domain/model/UserPreference";

export default class UserPreferencePost extends ApiRequest<any, UserPreferenceDto>
{
	constructor(token: string, userPreference:UserPreferenceDto) {
		super(
			"POST",
			UserPreferenceApiEndpoints.UPSERT ,
			{ Authorization: token },
				userPreference,
			{						
			}
		)
	}
}