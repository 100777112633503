import EstudioDetalleAvanzadoElemento from "./EstudioDetalleAvanzadoElemento";


interface EstudioDetalleAvanzadoEquipoEntity {

	id?: string;
	equipoId:string;
	equipoDescripcion:string;
	elementos: EstudioDetalleAvanzadoElemento[];
	unidades:string;
	tarifa:string;
	dias:string;
	orden: number;
	coste?: number;
	costeM2?: number;
}


export default class EstudioDetalleAvanzadoEquipo implements EstudioDetalleAvanzadoEquipoEntity {

	id?: string;
	equipoId:string;
	equipoDescripcion:string;
	elementos: EstudioDetalleAvanzadoElemento[];
	unidades:string;
	tarifa:string;
	dias:string;
	orden: number;
	coste?: number;
	costeM2?: number;

	constructor(entity: EstudioDetalleAvanzadoEquipoEntity) {

		this.id = entity.id;
		this.equipoId=entity.equipoId;
		this.tarifa=entity.tarifa;
		this.equipoDescripcion=entity.equipoDescripcion;
		this.elementos=entity.elementos;
		this.dias=entity.dias;
		this.unidades=entity.unidades;
		this.orden = entity.orden
	}

	public static toArray(entities: EstudioDetalleAvanzadoEquipoEntity[]): EstudioDetalleAvanzadoEquipo[] {
		return entities.map(entity => { return new EstudioDetalleAvanzadoEquipo(entity) });
	}
}

export interface EstudioDetalleAvanzadoEquipoDto extends EstudioDetalleAvanzadoEquipo {
	errors: string[]
};
