import { useContext } from "react";
import TableScreen from "../../components/common/TableScreen/Table.Screen";
import { AuthContext } from "../Login/AuthContextProvider";
import { VentanaPermiso } from "../Login/domain/model/VentanaPermiso";
import OfertasProvider from "./context/Ofertas.Provider";
import "./Ofertas.scss";
import OfertasFilterSection from "./views/objects/Filter section/Ofertas.FilterSection";
import OfertasGrid from "./views/objects/Ofertas.Grid";

interface Props {
	permisoVentana: VentanaPermiso
}

const Ofertas: React.FC<Props> = ({permisoVentana}: Props) => {	
	const { userPreferences} = useContext(AuthContext);
	
	return (
		<> {userPreferences &&
				<OfertasProvider>
				<div className='grid-view-container'>
					<TableScreen
						title="Ofertas"
						className="ofertas"
						filterSection={
							<div className="filters-container">
							<OfertasFilterSection />
							</div>
						}>
							
					</TableScreen>
					<OfertasGrid permisoVentana={permisoVentana} />
				</div>
				</OfertasProvider>
			}
		</>
	)
}

export default Ofertas;