import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { UserPreferenceApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { UserPreference } from "../../domain/model/UserPreference";

export default class UserPreferenceGet extends ApiRequest<any, BaseResponse<UserPreference[]>>
{
	constructor(token: string, userName:string) {
		super(
			"GET",
			UserPreferenceApiEndpoints.GET + `?SortingCriteria=CreationTime DESC&MaxResultCount=100&Filters[0].key=ApplicationCod&Filters[0].value=COMERCIAL&Filters[1].key=CreationUserName&Filters[1].value=${userName}&Filters[2].key=Component&Filters[2].value=Paginacion`,
			{ Authorization: token },
			undefined,
			{						
			}
		)
	}
}