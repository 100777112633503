import SelectableItem, { SelectableItemDto } from "../../../../components/ui/atoms/SingleSuggestionInput/model/SingleSuggestionInput.Model";
import { AutoCompleteSearchFilter, BiasedType } from "../../../../domain/BaseFilter";

type FilterExcludedProperties =
"id"
| "text";

type BiasedOferta = BiasedType<OfertaSelectable, FilterExcludedProperties>;

export type OfertaFilter = AutoCompleteSearchFilter<BiasedOferta>;

export interface OfertaDto {toneladas: string;};

export default class OfertaSelectable {
	constructor(
		readonly _id: string,
		readonly _descripcion: string,
        readonly _text: string,
        readonly _toneladas: string
	) {
		_id = _id;
        _descripcion = _descripcion;
        _text = _descripcion;
        _toneladas = _toneladas;
	}

	get descripcion() {
		return this._descripcion;
	}

    get id() {
		return this._id;
	}

    get text() {
		return this._text;
	}

    get toneladas() {
		return this._toneladas;
	}
}