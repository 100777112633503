import { useContext } from "react";
import TablePaginationTemplate from "../../../../components/common/TablePaginationTemplate/Table.Pagination.Template";
import IconButton from "../../../../components/ui/atoms/Buttons/IconButton";
import { HistoryHandler } from "../../../../context/History.Context";
import { Routes } from "../../../../router/routes/Routes";
import { AuthContext } from "../../../Login/AuthContextProvider";
import { VentanaPermiso } from "../../../Login/domain/model/VentanaPermiso";
import {
  OfertasPagination,
  OfertasTableData,
} from "../../context/Ofertas.Context";

interface Props {
  permisoVentana: VentanaPermiso;
}

const OfertasGrid: React.FC<Props> = ({ permisoVentana }: Props) => {
  const { columns, data, sumaToneladas } = useContext(OfertasTableData);
  const { userPreferences} = useContext(AuthContext);

  const {
    sorting,
    maxResultCount,
    onPageBackwards,
    onPageSelect,
    onSetMaxCount,
    onSort,
  } = useContext(OfertasPagination);
  const { goToRoute } = useContext(HistoryHandler);


  const AddButton = () => {
    return (
      <IconButton
        icon="add"
        onClick={() => goToRoute(Routes.OFERTAS_GRABACION)}
        label="Añadir"
      />
    );
  };
  return (
    <>
    {userPreferences && 
    <>
    <TablePaginationTemplate
      className="Ofertas"
      grid={{
        columns,
        data: data.items,
        sumaToneladas: sumaToneladas,
        sorting,
        rowAction:
          permisoVentana?.permisoModificacion || permisoVentana?.permisoEntrada
            ? {
                icon: permisoVentana?.permisoModificacion
                  ? "edit"
                  : "visibility",
                onClick: (id: string) =>
                  goToRoute(Routes.OFERTAS_EDICION, { id }),
              }
            : undefined,
        onSort: onSort("sortingCriteria"),
        headerBtn: permisoVentana?.permisoAlta && <AddButton />,
      }}
      pagination={{
        currentPage: data.currentPage,
        maxCount: userPreferences?.json!==undefined ? +userPreferences?.json?.maxPagination:maxResultCount,
        totalCount: data.totalCount,
        onSetMaxCount: onSetMaxCount("maxResultCount"),
        onPageBackwards,
        onPageSelect,
      }}
    /></>
}
    </>
  );
};

export default OfertasGrid;
