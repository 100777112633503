import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import BaseResponse from "../../../../domain/BaseResponse";
import EstudioRepository from "../../domain/EstudioRepository";
import Estudio, { EstudioDto } from "../../domain/model/Estudio";
import EstudioDetalle from "../../domain/model/EstudioDetalle";
import EstudioAdd from "../ws/Estudio.Request.Add";
import EstudioDelete from "../ws/Estudio.Request.Delete";
import EstudioRequestGetById from "../ws/Estudio.Request.GetById";
import EstudioRequestGetByOfertaId from "../ws/Estudio.Request.GetByOfertaId";
import EstudioRequestGetByPresupuestoId from "../ws/Estudio.Request.GetByPresupuestoId";
import EstudioRequestGetByPresupuestoIdWithDiscount from "../ws/Estudio.Request.GetByPresupuestoIdWithDiscount";
import EstudioRequestListByFilter from "../ws/Estudio.Request.ListByFilter";
import EstudioUpdate from "../ws/Estudio.Request.Update";
import EstudioUpdateTarifas from "../ws/Estudio.Request.UpdateTarifas";

export default class EstudioApiRepository
	extends ApiRepositoryBase<
		EstudioDto,
		Estudio
	> implements EstudioRepository {
	async getByPresupuestoIdWithDiscount(id: string): Promise<Estudio[]> {
		const res = await new EstudioRequestGetByPresupuestoIdWithDiscount(id, this.authToken).executeAsPromise();
		return Estudio.toArray(res?.items);
	}

	async add(data: Estudio): Promise<Estudio> {
		const res = await new EstudioAdd(data, this.authToken).executeAsPromise();
		return res;
	}

	async update(data: Estudio): Promise<Estudio> {
		return await new EstudioUpdate(data, this.authToken).executeAsPromise();
	}

	async delete(id: string): Promise<boolean> {
		await new EstudioDelete(id, this.authToken).executeAsPromise();
		return true;
	}

	async getById(id: string): Promise<any> {
		const res = await new EstudioRequestGetById(id, this.authToken).executeAsPromise();
		return res;
	}

	async getByOfertaId(id: string): Promise<Estudio[]> {
		const res = await new EstudioRequestGetByOfertaId(id, this.authToken).executeAsPromise();
		return Estudio.toArray(res?.items);
	}

	async getByOfertaCodigo(codigo: number, delegacionId: number): Promise<Estudio[]> {
		const res = await new EstudioRequestListByFilter(codigo, delegacionId,  this.authToken).executeAsPromise();
		return Estudio.toArray(res?.items);
	}

	async getByPresupuestoId(id: string): Promise<Estudio[]> {
		const res = await new EstudioRequestGetByPresupuestoId(id, this.authToken).executeAsPromise();
		return Estudio.toArray(res?.items);
	}

	async updateTarifas(id: string): Promise<EstudioDetalle[]> {
		const res = await new EstudioUpdateTarifas(id, this.authToken).executeAsPromise();
		return EstudioDetalle.toArray(res);
	}

	buildModelFromItems(item: EstudioDto): Estudio {
		return new Estudio(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse<EstudioDto>): BaseResponse<Estudio> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}
}