import { BaseRepository } from "@pavabits/components";


import { UserPreference, UserPreferenceDto } from "../../domain/model/UserPreference";
import UserPreferenceRepository from "../../domain/UserPreferenceRepository";
import UserPreferenceGet from "../ws/UserPreference.Get";
import UserPreferencePost from "../ws/UserPreference.Post";

export default class UserPreferenceApiRepository extends BaseRepository<any,any> implements UserPreferenceRepository {
	protected transformDtoIntoModel(dto: UserPreferenceDto[]) {
		if (dto && dto.length) {
					return dto.map(item => new UserPreference(item))
				}
				return [];
	}
	
	fetchUserPreference(userName:string): Promise<UserPreference[]> {
		return new Promise((resolve, reject) => {
			return new UserPreferenceGet(this.authToken, userName)
				.request()
				.then((response: any) => {
					if (response.success) {
						resolve(this.transformDtoIntoModel(response.data.items));
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error: any) => {
					reject(error);
				})
		});
	}
	setUserPreference(userPreference: UserPreference): Promise<UserPreference> {
		return new Promise((resolve, reject) => {
			return new UserPreferencePost(this.authToken, {applicationCod:userPreference.applicationCod, applicationName:userPreference.applicationName, component:userPreference.component, description:userPreference.description, json:userPreference.json.toString()})
				.request()
				.then((response: any) => {
					if (response.success) {
						resolve(new UserPreference(response.data));
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error: any) => {
					reject(error);
				})
		});
	}
	

}