import { FC, useContext } from "react";
import TableScreen from "../../components/common/TableScreen/Table.Screen";
import { AuthContext } from "../Login/AuthContextProvider";
import OportunidadesProvider from "./context/Oportunidades.Provider";
import OportunidadesFilterSection from "./views/objects/Oportunidades.FilterSection";
import OportunidadesGrid from "./views/objects/Oportunidades.Grid";

const OportunidadesAsfalto: FC<{}> = () => {

	const { userPreferences} = useContext(AuthContext);

	return (
		<>{ userPreferences && 
			<OportunidadesProvider>
			<div className='grid-view-container'>
				<TableScreen
					title="Oportunidades"
					filterSection={(
						<OportunidadesFilterSection />
					)}
				>
				</TableScreen>
				<OportunidadesGrid />
				</div>
			</OportunidadesProvider>
			}
		</>
	)
}

export default OportunidadesAsfalto;