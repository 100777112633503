import React, { useCallback, useContext, useEffect, useState } from 'react'
import { GlobalContext, GlobalContextType } from '../../../context/Global.Context';
import FormField from '../../../components/ui/molecules/Form-field/FormField';
import SingleSuggestionInputField, { SingleSuggestionInputFieldProps } from '../../../components/ui/atoms/SingleSuggestionInput/SingleSuggestionInput.Field';
import { AuthContext } from '../../Login/AuthContextProvider';
import ClienteRepository from '../../Oportunidades/domain/ClienteRepository';
import ClienteApiRepository from '../../Oportunidades/infraestructure/api/Cliente.ApiRepository';
import Promotor from '../../Oportunidades/domain/model/Promotor';

const SeguimientoClienteClienteIdSearchField: React.FC = () => {
  const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;
  const componentName = "seguimientoCliente";
  const [clientesSearch, setClientesSearch] = useState<Promotor[]>();
  const [value, setValue] = useState(globalState.seguimientoCliente?.filters?.clienteId ?? "");
  const { getToken } = useContext(AuthContext);

  useEffect(() => {
    searchCliente(value)
  }, [value])

  const handleInputChange = (value: string) => {
    updateGlobalState(componentName, {
      filters: {...globalState.seguimientoCliente?.filters,
      "clienteId":  value.length !== 0 ? Number(value) : undefined, 
      "clienteDescripcion": clientesSearch?.find(x => x.id == value)?.descripcion},
      pagination: {
        skipCount: 0
      }})
}

const searchCliente = useCallback((search: string) => {
  const repoCliente: ClienteRepository = new ClienteApiRepository(getToken());
  return repoCliente.searchCliente({ filter: { descripcion: search} }).then((resp) => {
    setClientesSearch(resp);
    return resp;
  })
}, [getToken])


return (
  <>
  {  clientesSearch &&
  <FormField<SingleSuggestionInputFieldProps>
  label="Cliente"
  value={{ id: value, 
      text: clientesSearch?.find(x => x.id == value)?.descripcion ?? ""}}
  onChange={handleInputChange}
  searchCallback={(searchCliente)}
  component={SingleSuggestionInputField}
  idTest="FiltroSeguimientoClienteInput"
/>}
  </>

  )
}

export default SeguimientoClienteClienteIdSearchField