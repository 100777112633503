import { useContext } from "react";
import TablePaginationTemplate from "../../../../components/common/TablePaginationTemplate/Table.Pagination.Template";
import IconButton from "../../../../components/ui/atoms/Buttons/IconButton";
import { GlobalContext, GlobalContextType } from "../../../../context/Global.Context";
import { HistoryHandler } from "../../../../context/History.Context";
import { Routes } from "../../../../router/routes/Routes";
import { AuthContext } from "../../../Login/AuthContextProvider";
import { VentanaPermiso } from "../../../Login/domain/model/VentanaPermiso";
import { ClientePagination, ClienteTableData } from "../../context/Cliente.Context";
import ClienteFilterSection from "./Filter section/Cliente.FilterSection";

interface Props {
	permisoVentana: VentanaPermiso
}

const ClienteGrid: React.FC<Props> = ({permisoVentana}: Props) => {
	const { globalState } = useContext(GlobalContext) as GlobalContextType;
	const { columns, data } = useContext(ClienteTableData);	
  	const { goToRoute } = useContext(HistoryHandler);
	const { userPreferences} = useContext(AuthContext);
	
	const componentName = "clientes"
	const { sorting, maxResultCount, onPageBackwards, onPageSelect, onSetMaxCount, onSort } = useContext(ClientePagination);
	
	const AddButton = () => {
		return (
			<IconButton
				icon="add"
				onClick={() => goToRoute(Routes.CLIENTES_GRABACION)}
				label={"Añadir"}
			/>
		)
	}

	return (
		<>
		 {userPreferences &&
			<div className="grid-view-container">
			<TablePaginationTemplate
				title={"Clientes"}
				grid={
					{
						columns,
						data: data.items,
						sorting,
						rowAction: permisoVentana?.permisoModificacion || permisoVentana?.permisoEntrada ? {
							icon: permisoVentana?.permisoModificacion ? "edit" : "visibility",
							onClick: (id: string) => {
								goToRoute(Routes.CLIENTES_EDICION, { id })
							}
						} : undefined,
						onSort: onSort("sortingCriteria"),
						headerBtn: permisoVentana?.permisoAlta && <AddButton />
					}
				}
				pagination={
					{
						currentPage: globalState[componentName]?.pagination?.currentPage ?? data.currentPage,
						maxCount: userPreferences?.json!==undefined ? +userPreferences?.json?.maxPagination:maxResultCount,
						totalCount: globalState[componentName]?.pagination?.totalCount ?? data.totalCount,
						onSetMaxCount: onSetMaxCount("maxResultCount"),
						onPageBackwards,
						onPageSelect,
					}
				}
				filterSection={
				    <div className="filters-container">
						<ClienteFilterSection />
					</div>
				}
			/>
		</div>
			}
		</>
	)
}

export default ClienteGrid;