import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { SeguimientoOfertaEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { SeguimientoOfertaDto } from "../../domain/model/SeguimientoOferta";

export default class SeguimientoOfertaRequestGetByOfertaId extends ApiRequest<{}, BaseResponse<SeguimientoOfertaDto[]>>
{
	constructor(id: string, token: string) {
		super(
			"POST",
			SeguimientoOfertaEndpoints.CON_FILTRO,
			{ Authorization: token },
			{ "filter": {
				"ofertaId": id
			  },
			  "SortingCriteria":"FechaSeguimiento DESC, LastModificationTime DESC"
			}
		)
	}
}