import { useCallback, useContext, useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { toast } from "react-toastify";
import '../../assets/styles/elements/_footers.scss';
import CustomGridView from "../../components/CustomGridView";
import FilterModal from "../../components/ui/atoms/FilterModal/FilterModal";
import PaginationComponent from "../../components/ui/molecules/Pagination/PaginationComponent";
import { GlobalContext, GlobalContextType } from "../../context/Global.Context";
import { useLoading } from "../../context/Loading.Context";
import BaseResponse from "../../domain/BaseResponse";
import DelegacionesRepository from "../../domain/DelegacionesRepository";
import Delegacion from "../../domain/model/Delegacion";
import useColumnasGrid from "../../hooks/useColumnasGrid";
import useDebounce from "../../hooks/useDebounce";
import DelegacionesApiRepository from "../../infraestructure/api/Delegaciones.ApiRepository";
import DeleteModal from "../../shared/components/DeleteModal/DeleteModal";
import { formatNumber } from "../../utils/NumberUtil";
import { AuthContext } from "../Login/AuthContextProvider";
import ElementoTipoRepository from "./domain/ElementoTipoRepository";
import EquiposRepository from "./domain/EquiposRepository";
import ElementoEquipo, { ElementoEquipoReadDto, UnidadObraElementoTipo } from "./domain/model/ElementoEquipo";
import { EquipoReadDto } from "./domain/model/Equipo";
import EquiposDelegacionesDropdown from "./EquiposDelegacionesDropdown";
import EquipoDescripcionSearchField from "./EquiposDescripcionSearchField";
import EquiposNombreSearchField from "./EquiposNombreSearchField";
import EquiposTagCloud from "./EquiposTagCloud";
import EquiposTipoDropdown from "./EquiposTipoDropdown";
import ElementoTipoApiRepository from "./infrastructure/api/ElementoTipo.Api.Repository";
import EquipoApiRepository from "./infrastructure/api/Equipo.ApiRepository";

const Equipos = () => {
  const [delegaciones, setDelegaciones] = useState<Delegacion[]>();
  const [tipos, setTipos] = useState<UnidadObraElementoTipo[]>([]);
  const [elementos, setElementos] = useState<ElementoEquipo[]>([]);
  const [elementosResponse, setElementosResponse] = useState<BaseResponse<ElementoEquipo[]>>();
  const [equipos, setEquipos] = useState<EquipoReadDto[]>([]);
  const [equiposResponse, setEquiposResponse] = useState<BaseResponse<EquipoReadDto[]>>();

  const { getToken, getDelegacion, userPreferences } = useContext(AuthContext);
  const equiposRepo: EquiposRepository = new EquipoApiRepository(getToken());
  const { globalState, updateGlobalState } = useContext(GlobalContext) as GlobalContextType;


  const [elementoDeletionIndex, setElementoDeletionIndex] = useState<string>("");
  const [equipoDeletionIndex, setEquipoDeletionIndex] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false)
  const { setLoading } = useLoading();
  const { cols: elementosCols } = useColumnasGrid("BACKENDOPORTUNIDADES", "EQUIPOSELEMENTOS");
  const { cols: equiposCols } = useColumnasGrid("BACKENDOPORTUNIDADES", "EQUIPOS");
  const [isChecked, setIsChecked] = useState<boolean>(true);

  const elementosMaxResultCount = userPreferences?.json?.maxPagination!==undefined ? +userPreferences?.json?.maxPagination: 10;
  const equiposMaxResultCount = userPreferences?.json.maxPagination!==undefined ? +userPreferences?.json?.maxPagination: 10;
  const elementosSkipCount = globalState?.elementos?.pagination?.skipCount ?? 0;
  const equiposSkipCount = globalState?.equipos?.pagination?.skipCount ?? 0;

  const sortingElementos = globalState.elementos?.order ? globalState.elementos?.order?.sortingCriteria + (globalState.elementos?.order?.desc ? " DESC" : "") : "LastModificationTime DESC";
  const sortingEquipos = globalState.equipos?.order ? globalState.equipos?.order?.sortingCriteria + (globalState.equipos?.order?.desc ? " DESC" : "") : "LastModificationTime DESC";
  const [openDelete, setOpenDelete] = useState<boolean>(false);


  const filterValuesEquipos = {
    userDelegacion: isChecked && getDelegacion().id ? getDelegacion().id : undefined,
    nombre: globalState.equipos?.filters?.nombre,
    descripcion: globalState.equipos?.filters?.descripcion,
    delegacionIds: globalState?.equipos?.filters?.delegacionIds,
  }

  const filterValuesElementos = {
    userDelegacion: isChecked && getDelegacion().id ? getDelegacion().id : undefined,
    nombre: globalState.elementos?.filters?.nombre,
    descripcion: globalState.elementos?.filters?.descripcion,
    delegacionIds: globalState?.elementos?.filters?.delegacionIds,
    tipoElementoIds: globalState.elementos?.filters?.tipoIds,
  }

  const [elementosFilterData, setElementosFilterData] = useState({
    filter: globalState?.elementos?.filters ? globalState?.elementos?.filters : { filters: {} },
    sortingCriteria: sortingElementos,
    maxResultCount: elementosMaxResultCount,
    skipCount: elementosSkipCount,
  })

  const [equiposFilterData, setEquiposFilterData] = useState({
    filter: globalState?.equipos?.filters ? globalState?.equipos?.filters : { filters: {} },
    sortingCriteria: sortingEquipos,
    maxResultCount: equiposMaxResultCount,
    skipCount: equiposSkipCount,
  })

  const debouncedNombreSearchValue = useDebounce(filterValuesEquipos.nombre, 1000)
  const debouncedDescripcionSearchValue = useDebounce(filterValuesEquipos.descripcion, 1000)
  const debouncedDelegacionSearchValue = useDebounce(filterValuesEquipos.delegacionIds, 1000)
  const debouncedElementosNombreSearchValue = useDebounce(filterValuesElementos.nombre, 1000)
  const debouncedElementosDescripcionSearchValue = useDebounce(filterValuesElementos.descripcion, 1000)
  const debouncedElementosDelegacionSearchValue = useDebounce(filterValuesElementos.delegacionIds, 1000)
  const debouncedElementosTipoSearchValue = useDebounce(filterValuesElementos.tipoElementoIds, 1000)

  useEffect(() => {
    if (isChecked) {
      filterValuesElementos.delegacionIds = [Number(getDelegacion().id)];
      filterValuesEquipos.delegacionIds = [Number(getDelegacion().id)];
      updateGlobalState("elementos", { filters: { ...globalState.elementos?.filters, userDelegacion: getDelegacion() } })
      updateGlobalState("equipos", { filters: { ...globalState.equipos?.filters, userDelegacion: getDelegacion() } })
    } else {
      filterValuesElementos.delegacionIds = globalState.elementos?.filters?.delegacionIds;
      filterValuesEquipos.delegacionIds = globalState.equipos?.filters?.delegacionIds;
    }
    fetchElementos()
    fetchEquipos()
  }, [
    elementosMaxResultCount,
    equiposMaxResultCount,
    elementosSkipCount,
    equiposSkipCount,
    sortingElementos,
    sortingEquipos,
    debouncedNombreSearchValue,
    debouncedDescripcionSearchValue,
    debouncedDelegacionSearchValue,
    debouncedElementosNombreSearchValue,
    debouncedElementosDescripcionSearchValue,
    debouncedElementosDelegacionSearchValue,
    debouncedElementosTipoSearchValue,
    filterValuesEquipos.userDelegacion,
    filterValuesElementos.userDelegacion
  ])

  useEffect(() => {
    setEquiposFilterData({
      ...equiposFilterData,
      sortingCriteria: globalState?.equipos?.order ? (globalState?.equipos?.order?.sortingCriteria + " " + (globalState?.equipos?.order?.desc ? "DESC" : "ASC")) : "LastModificationTime DESC"
    })
  }, [globalState?.equipos?.order])

  useEffect(() => {
    setElementosFilterData({
      ...elementosFilterData,
      sortingCriteria: globalState?.elementos?.order ? (globalState?.elementos?.order?.sortingCriteria + " " + (globalState?.elementos?.order?.desc ? "DESC" : "ASC")) : "LastModificationTime DESC"
    })
  }, [globalState?.elementos?.order])

  const fetchElementos = async () => {
    setLoading(true);
    setElementosResponse(undefined);
    setElementos([]);
    return equiposRepo.fetchElementosByFilter(
      {
        filter: filterValuesElementos,
        sortingCriteria: sortingElementos,
        maxResultCount: elementosMaxResultCount,
        skipCount: elementosSkipCount,
      })
      .then((res: BaseResponse<ElementoEquipoReadDto[]>) => {
        setElementosResponse(res);
        setElementos(res?.items.map((element) => {
          return { ...element, tarifa: formatNumber(+element.tarifa), tipoUnidad: element.tipoUnidad! };
        }))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const fetchEquipos = async () => {
    setLoading(true);
    return equiposRepo.fetchEquiposByFilter({
      filter: filterValuesEquipos,
      sortingCriteria: sortingEquipos,
      maxResultCount: equiposMaxResultCount,
      skipCount: equiposSkipCount,
    })
      .then((res: BaseResponse<EquipoReadDto[]>) => {
        setEquiposResponse(res)
        setEquipos(res?.items.map((element) => {
          return { ...element, tarifa: element.tarifa ? formatNumber(+element.tarifa) : "0" };
        }))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    const tipoRepo: ElementoTipoRepository = new ElementoTipoApiRepository(getToken());
    tipoRepo.fetchTipos().then((res) => {
      setTipos(res.filter(x => x.visible));
    });
    fetchDelegaciones();
  }, [getToken()])

  const fetchDelegaciones = useCallback(() => {
    const repo: DelegacionesRepository = new DelegacionesApiRepository(getToken());
    return repo.fetchDelegaciones({
      filter: {
        ids: [0, 1, 2, 3, 4, 5, 6, 7, 50, 55]
      }
    })
      .then((res) => {
        setDelegaciones(res)
      })
  }, [getToken])

  useEffect(() => {
    handleSearch();
  }, [
    debouncedNombreSearchValue,
    debouncedDescripcionSearchValue,
    debouncedDelegacionSearchValue,
    debouncedElementosNombreSearchValue,
    debouncedElementosDescripcionSearchValue,
    debouncedElementosDelegacionSearchValue,
    debouncedElementosTipoSearchValue
  ])

  const handleSearch = () => {
    setLoading(true);
    if (globalState?.equipos?.filters) {
      setEquiposFilterData({ ...equiposFilterData, filter: filterValuesEquipos })
    }
    if (globalState?.elementos?.filters) {
      setElementosFilterData({ ...elementosFilterData, filter: filterValuesElementos })
    }
    setLoading(false)
  }

  const handleCloseModalDelete = (remove: boolean) => {
    setOpenDelete(false);
    if (remove) {
      handleRemoveElemento();
    }
  }
  const onDeleteElement = async (id: string) => {
    setElementoDeletionIndex(id);
    setOpenDelete(true);
  }

  const onDeleteEquipo = async (id: string) => {
    setEquipoDeletionIndex(id);
    setOpenDelete(true);
  }

  const handleRemoveElemento = () => {
    if (elementoDeletionIndex) {
      equiposRepo.deleteElemento(elementoDeletionIndex)
        .then(resp => {
          setOpenDelete(false)
          toast.success("Elemento de equipo eliminado con éxito");
          if (isChecked)
            filterValuesElementos.delegacionIds = filterValuesElementos.delegacionIds !== undefined ? [...filterValuesElementos.delegacionIds, Number(getDelegacion().id)] : [Number(getDelegacion().id)];
          setElementoDeletionIndex("");
          fetchElementos();
        })
        .catch(err => {
          console.error(err)
          toast.error("Error al eliminar el Elemento");
        })
    } else if (equipoDeletionIndex) {
      equiposRepo.deleteEquipo(equipoDeletionIndex)
        .then(resp => {
          setOpenDelete(false)
          toast.success("Equipo eliminado con éxito");
          if (isChecked)
            filterValuesEquipos.delegacionIds = filterValuesEquipos.delegacionIds !== undefined ? [...filterValuesEquipos.delegacionIds, Number(getDelegacion().id)] : [Number(getDelegacion().id)];
          setEquipoDeletionIndex("");
          fetchEquipos();
        })
        .catch(err => {
          console.error(err)
          toast.error("Error al eliminar el Equipo");
        })
    }
  }

  const queryParameters = new URLSearchParams(window.location.search)
  const backUrl = Number(queryParameters.get("backUrl"))
  const [tabIndex, setTabIndex] = useState(backUrl ?? 0);
  const changeTab = (index: number) => {
    setTabIndex(index);
  }

  const handleSetMaxCount = (count: number) => {
    if (tabIndex === 0) {
      setElementosFilterData({ ...elementosFilterData, maxResultCount: count })
      updateGlobalState("elementos", { pagination: { ...globalState.elementos?.pagination, maxResultCount: count } })
    }
    if (tabIndex === 1) {
      setEquiposFilterData({ ...equiposFilterData, maxResultCount: count })
      updateGlobalState("equipos", { pagination: { ...globalState.equipos?.pagination, maxResultCount: count } })
    }
  }

  const handlePageSelect = (page: number) => {
    if (tabIndex === 0) {
      setElementosFilterData({ ...elementosFilterData, skipCount: page * elementosMaxResultCount })
      updateGlobalState("elementos", { pagination: { ...globalState.elementos?.pagination, skipCount: page * elementosMaxResultCount } })
    }
    if (tabIndex === 1) {
      setEquiposFilterData({ ...equiposFilterData, skipCount: page * equiposMaxResultCount })
      updateGlobalState("equipos", { pagination: { ...globalState.equipos?.pagination, skipCount: page * equiposMaxResultCount } })
    }
  }

  const handlePageBackwards = () => {
    if (tabIndex === 0) {
      setElementosFilterData({ ...elementosFilterData, skipCount: (elementosSkipCount / elementosMaxResultCount - 1) * elementosMaxResultCount })
      updateGlobalState("elementos", { pagination: { ...globalState.elementos?.pagination, skipCount: (elementosSkipCount / elementosMaxResultCount - 1) * elementosMaxResultCount } })
    }
    if (tabIndex === 1) {
      setEquiposFilterData({ ...elementosFilterData, skipCount: (equiposSkipCount / equiposMaxResultCount - 1) * equiposMaxResultCount })
      updateGlobalState("equipos", { pagination: { ...globalState.equipos?.pagination, skipCount: (equiposSkipCount / equiposMaxResultCount - 1) * equiposMaxResultCount } })
    }
  }

  const onCheckChange = async () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      filterValuesElementos.delegacionIds = [Number(getDelegacion().id)];
      filterValuesEquipos.delegacionIds = [Number(getDelegacion().id)];
      filterValuesElementos.userDelegacion = getDelegacion().id;
      filterValuesEquipos.userDelegacion = getDelegacion().id;
      updateGlobalState("elementos", {
        filters: {
          ...globalState.elementos?.filters, userDelegacion: getDelegacion()
        },
        pagination: {
          skipCount: 0
        }
      })
      updateGlobalState("equipos", {
        filters: {
          ...globalState.equipos?.filters, userDelegacion: getDelegacion()
        },
        pagination: {
          skipCount: 0
        }
      })
    } else {
      filterValuesElementos.delegacionIds = globalState.elementos?.filters?.delegacionIds;
      filterValuesEquipos.delegacionIds = globalState.equipos?.filters?.delegacionIds;
      filterValuesElementos.userDelegacion = "";
      filterValuesEquipos.userDelegacion = "";
      updateGlobalState("elementos", { filters: { ...globalState.elementos?.filters, userDelegacion: undefined } })
      updateGlobalState("equipos", { filters: { ...globalState.equipos?.filters, userDelegacion: undefined } })
    }
    // fetchElementos();
    // fetchEquipos()
  }


  return (
    <>
      { userPreferences &&
      <div className="grid-view-container">
      <h3>Equipos</h3>
      <div className="filters-container">
        <div className="filters-modal-container">
          <FilterModal
            titleShow="Filtros"
            modalTitle="Selección de filtros"
            label = {"TituloModal"}
            filters={
              <>
                <EquiposDelegacionesDropdown
                  delegaciones={delegaciones ? delegaciones : []}
                  disabled={isChecked}
                />
                <EquiposNombreSearchField idTest="NombreFiltro"/>
                {tabIndex == 0 && <EquiposTipoDropdown unidadObraElementoTipos={tipos ? tipos : []} idTest="TipoFiltro"/>}
                <EquipoDescripcionSearchField idTest="DescripcionFiltro"/>
              </>
            }

          />
          <label>Mis datos</label>
          <input
            checked={isChecked}
            onChange={onCheckChange}
            type="checkbox"
            data-cy="MisDatosCheckbox"
          />
        </div>
        <EquiposTagCloud />
      </div>

      <Tabs selectedIndex={tabIndex} onSelect={(index) => changeTab(index)}>
        <TabList>
          <Tab data-cy="ElementosTab">Elementos</Tab>
          <Tab data-cy="EquiposTab">Equipos</Tab>
        </TabList>
        <TabPanel>
          {elementos && elementosCols &&
            <>
              <CustomGridView
                title="elementos"
                columns={elementosCols}
                data={elementos}
                addButton
                controlButtons
                functionalityPath="/mantenimiento/elementos"
                onDelete={onDeleteElement}
                sorting={sortingElementos}
              />

              <div className="footer-section">
                <PaginationComponent
                  currentPage={elementosResponse?.currentPage ?? 1}
                  maxCount={elementosFilterData.maxResultCount}
                  totalCount={elementosResponse?.totalCount ?? 0}
                  onPageSelect={handlePageSelect}
                  onSetMaxCount={handleSetMaxCount}
                  onPageBackwards={handlePageBackwards}
                />
              </div>
            </>
          }
        </TabPanel>
        <TabPanel>

          {equipos && equiposCols &&
            <>
              <CustomGridView
                title="equipos"
                columns={equiposCols}
                data={equipos}
                addButton
                functionalityPath="/mantenimiento/equipos"
                controlButtons
                onDelete={onDeleteEquipo}
                sorting={sortingEquipos}
              />

              <div className="footer-section">
                <PaginationComponent
                  currentPage={equiposResponse?.currentPage ?? 1}
                  maxCount={equiposFilterData.maxResultCount}
                  totalCount={equiposResponse?.totalCount ?? 0}
                  onPageSelect={handlePageSelect}
                  onSetMaxCount={handleSetMaxCount}
                  onPageBackwards={handlePageBackwards}
                />
              </div>
            </>
          }
        </TabPanel>
      </Tabs>

      {
        openDelete &&
        <DeleteModal
          open={openDelete}
          handleCloseModal={handleCloseModalDelete}
          field="elemento o equipo"
        >
        </DeleteModal>
      }

      </div>
      }
    </>
  )
}

export default Equipos