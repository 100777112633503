import { Icon } from "@pavabits/components";
// import "./IconButton.scss";
import "../../../../assets/styles/components/icons.scss"

interface Props {
	icon: string
	className?: string
	onClick?: () => void
	disabled?: boolean
	label?: string
	idTest?: string
	index?: any
}

const IconButton: React.FC<Props> = (
	{
		icon,
		className,
		onClick,
		disabled,
		label,
		idTest,
		index
	}
) => {

	const classNames = ['icon-button', className].join(" ").trimEnd();


	const generateDataCyButtons = (label?: string, index?: any, icon?: string): string => {

		const capitalizeFirstLetter = (str: string): string => {
			return str.charAt(0).toUpperCase() + str.slice(1);
		};


		if (label !== undefined) {
			return index !== undefined
				? capitalizeFirstLetter(label) + index
				: capitalizeFirstLetter(label);
		}

		return index !== undefined
			? capitalizeFirstLetter(icon || '') + index
			: capitalizeFirstLetter(icon || '');
	};


	if (disabled) {
		return (

			<>
				<button
					type="button"
					className={classNames}
					onClick={onClick}
					data-cy={generateDataCyButtons(label, index, icon)}
					disabled
					title={disabled ? "No es posible eliminar este registro" : "Eliminar"}
				>
					<Icon icon={icon} />
				</button>
			</>

		)
	}
	else {
		return (
			<>
				<button
					type="button"
					className={classNames}
					onClick={onClick}
					data-cy={generateDataCyButtons(label, index, icon)}

				>
					<Icon icon={icon} />
				</button>
			</>

		)
	}
}

export default IconButton;