import { Icon } from "@pavabits/components"
import { useState } from "react"

import useComponentVisible from "../../../../hooks/useComponentVisible"
import "./SingleSuggestionInput.scss";
import { SelectorType } from "./hooks/useSingleSelectorItems";
import TooltipComponent from "../../molecules/Tooltip/TooltipComponent";

interface SingleSuggestionInputProps {
	input: string,
	suggestions: SelectorType[],
	disabled?: boolean
	className?: string
	placeholder?: string
	cleanButton?:boolean
	onInputChange: (event: any) => void
	required?: boolean
	idTest?:string
}

export const SingleSuggestionInput: React.FC<SingleSuggestionInputProps> = (props: SingleSuggestionInputProps) => {

	const {
		
		input,
		disabled,
		className,
		suggestions,
		placeholder,
		cleanButton,
		onInputChange,
		required,
		idTest
	} = props;

	const minInputReached = input.length >= 3;

	const [targetItem, setTargetItem] = useState<string>("");

	const [visible, setVisible] = useState<boolean>(false);
	const [showList, setShowList] = useState<boolean>(visible && minInputReached && suggestions.length ? true : false);

	function onFocusLost(target?: string) {
		//setVisible(false);
		setShowList(false);
		setTargetItem(target ?? "");
	}

	const { ref } = useComponentVisible(onFocusLost);

	function showOptions() {
		// setVisible(
		// 		targetItem === "" ||
		// 		(targetItem.length > 0 && !targetItem.includes("PbDropdownItem"))
		// );
		setShowList(targetItem === "" || (targetItem.length > 0 && !targetItem.includes("PbDropdownItem")));
		setTargetItem("");

	}

	function handleClick(e: any) {
		const value = e.target.value?.toString() ?? "";
		const suggestion = suggestions.find(sugg => sugg.id.toString() === value); 
		if (suggestion) suggestion.onClick(suggestion.selected, suggestion.id.toString());
	}

	function handleInput(e: any) {
		if (minInputReached && suggestions.length) {
			setVisible(true);
			setShowList(true);
		}
		onInputChange(e);
	}

	function handleClose(e:any) {
		if(suggestions && suggestions.length > 0) suggestions[0].clearAll();
	}

	//const showList = visible && minInputReached && suggestions.length;
	const listClassName = `suggestionInput__list${showList ? ' visible' : ' hide'}`;

	return (
		<div className="suggestionInput">
			<TooltipComponent
			text={input}>
			<input
				className={`${className} form-control${disabled ? " disabled" : ""}`}
				type="text"
				value={input}
				disabled={disabled}
				placeholder={placeholder}
				onChange={handleInput}
				onClick={showOptions}
				data-cy={idTest}
				required={required}
			/>
			</TooltipComponent>

			{
				// cleanButton && 
				// <>
				// <button type="button" className={"btn btn--suggestion cancel lef"} onClick={handleClose}><Icon icon="close"/></button>
				
				// </>
			}
			
			<ul
				ref={ref}
				className={listClassName}
				style={{ listStyleType: "none" }}
			>
				{minInputReached && suggestions.map((option, index) => {
					const isFirstItem = index === 0;
					const isLastItem = index === suggestions.length - 1;
					const itemClassName = `suggestionInput__item${isFirstItem ? ' first' : isLastItem ? ' last' : ''}`;
					return (
						<li id={`PbDropdownItem-${index}`} className={itemClassName} key={index}>
							<button type="button" className="btn btn--selector" id={`PbDropdownItem-${index}`} onClick={handleClick} value={option.id}>
								{option.selected && <Icon icon="done" />}
								{option.text}
							</button>
						</li>
					)
				})
				}
			</ul>
		</div>
	)
}