import { usePsQuery } from "@pavabits/components";
import { useCallback, useContext } from "react";
import { GlobalContext, GlobalContextType } from "../../../context/Global.Context";
import { useLoading } from "../../../context/Loading.Context";
import { BaseFilter } from "../../../domain/BaseFilter";
import BaseResponse from "../../../domain/BaseResponse";
import { AuthContext } from "../../Login/AuthContextProvider";
import Ofertas, { OfertasFilter } from "../domain/model/Ofertas";
import OfertasRepository from "../domain/OfertasRepository";
import OfertasApiRepository from "../infraestructure/api/Ofertas.ApiRepository";

function useOfertasPost(filter: BaseFilter<OfertasFilter>): BaseResponse<Ofertas[]> {
	const {getToken, getDelegacion,userPreferences} = useContext(AuthContext);
	const {setLoading} = useLoading();
	const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;

	const fetchOfertasFromApi = useCallback(() => {
		setLoading(true);
		const repo: OfertasRepository = new OfertasApiRepository(getToken());
		filter.sortingCriteria= filter.sortingCriteria == "" ? "fechaOferta DESC" : filter.sortingCriteria;
		let misdatos=undefined;
		if(globalState?.ofertas?.filters?.isChecked || globalState?.ofertas?.filters?.isChecked==undefined || (filter.filter )==undefined && getDelegacion().id!=""){
			misdatos = +getDelegacion().id
		}
		if(userPreferences)
			filter.maxResultCount=+userPreferences?.json?.maxPagination;
		return repo.fetchOfertasByFilter(filter, misdatos).finally(() => {setLoading(false)});
	}, [filter, globalState?.ofertas?.filters, getDelegacion().id, userPreferences?.json?.maxPagination]);

	const { data } = usePsQuery(fetchOfertasFromApi);

	// useEffect(() => {
	// 	updateGlobalState("ofertas", {
	// 		pagination: {
	// 			...globalState.ofertas?.pagination, 
	// 			currentPage: data?.currentPage,
	// 			totalCount: data?.totalCount,
	// 			maxResultCount: filter?.maxResultCount,
	// 			skipCount: filter?.skipCount
	// 		}})
	// 		}, [data, globalState?.ofertas?.filters?.isChecked])

	return {
		currentPage: data?.currentPage ?? 0,
		items: data?.items ?? [],
		totalCount: data?.totalCount ?? 0
	}
}

export default useOfertasPost;
