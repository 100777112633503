interface SeguimientoClienteEntity  {
	id: string,
	fechaSeguimiento: string,
	//observacionesTipoSeguimiento:string|undefined,
	observaciones:string|undefined,
	tipoSeguimientoClienteId:number,
	clienteId: number | undefined,
	estadoOfertaId:number,
	lastModificationTime:string,
	contactoId:string,
	ofertaId:string
	seguimientoOfertaId:string;
	clienteNombre?: string | undefined;
	ofertaCodigo?: string;
	ofertaDescripcion?: string;
	ofertaToneladas?: string;
}


export default class SeguimientoCliente implements SeguimientoClienteEntity {

	id: string;
	fechaSeguimiento: string;
	//observacionesTipoSeguimiento:string | undefined;
	observaciones:string | undefined;
	clienteId: number | undefined;
	tipoSeguimientoClienteId:number;
	estadoOfertaId:number;
	lastModificationTime:string;
	contactoId:string;
	ofertaId:string;
	seguimientoOfertaId:string;
	clienteNombre?: string | undefined;
	ofertaCodigo?: string;
	ofertaDescripcion?: string;
	ofertaToneladas?: string;

	

	constructor(entity: SeguimientoClienteEntity) {
		
		this.id = entity.id;
		this.fechaSeguimiento = entity.fechaSeguimiento;
		//this.observacionesTipoSeguimiento = entity.observacionesTipoSeguimiento;
		this.clienteId=entity.clienteId;
		this.tipoSeguimientoClienteId = entity.tipoSeguimientoClienteId;
		this.observaciones = entity.observaciones;
		this.lastModificationTime = entity.lastModificationTime;
		this.contactoId=entity.contactoId;
		this.estadoOfertaId=entity.estadoOfertaId;
		this.ofertaId=entity.ofertaId;
		this.seguimientoOfertaId=entity.seguimientoOfertaId;
		this.clienteNombre = entity.clienteNombre;
		this.ofertaCodigo = entity.ofertaCodigo;
		this.ofertaDescripcion = entity.ofertaDescripcion;
		this.ofertaToneladas = entity.ofertaToneladas;
	}

	public static toArray(entities: SeguimientoClienteEntity[]): SeguimientoCliente[] {
		return entities.map(entity => { return new SeguimientoCliente(entity) });
	}
}


export interface SeguimientoClienteDto  extends SeguimientoCliente { 
	errors:string[]
	};

export interface SeguimientoClienteReadDto extends SeguimientoCliente {
	
}


export interface SeguimientoClienteCreationDto extends SeguimientoCliente { 
	errors:string[]
	};
export type SeguimientoClienteUpdateDto = SeguimientoCliente;