import { useCallback, useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { toast } from "react-toastify";
import IconButton from "../../../components/ui/atoms/Buttons/IconButton";
import Dropdown, { DropdownProps } from "../../../components/ui/atoms/Dropdown/Dropdown";
import FormInput from "../../../components/ui/atoms/Input/FormInput/FormInput";
import ModalPage from "../../../components/ui/atoms/ModalPage/ModalPage";
import FormField from "../../../components/ui/molecules/Form-field/FormField";
import { useLoading } from "../../../context/Loading.Context";
import UnidadMedida, { UnidadMedidaDto } from "../../../domain/model/UnidadMedida";
import UnidadesMedidaRepository from "../../../domain/UnidadesMedidaRepository";
import { FormErrors } from "../../../hooks/use-form";
import UnidadesMedidaApiRepository from "../../../infraestructure/api/UnidadesMedida.ApiRepository";
import { CustomButton } from "../../../shared/components/Buttons/CustomButton";
import DeleteModal from "../../../shared/components/DeleteModal/DeleteModal";
import { formatDate2input } from "../../../utils/DateUtil";
import { formatNumber } from "../../../utils/NumberUtil";
import EstudioRepository from "../../Estudios/domain/EstudioRepository";
import Estudio from "../../Estudios/domain/model/Estudio";
import EstudioDetalle from "../../Estudios/domain/model/EstudioDetalle";
import EstudioApiRepository from "../../Estudios/Infraestructure/api/Estudio.ApiRepository";
import { AuthContext } from "../../Login/AuthContextProvider";
import Ofertas from "../../Ofertas/domain/model/Ofertas";
import { medicionByUnidadMedida } from "../../Ofertas/views/objects/Modal section/ModalesCalculosAvanzados/Calculos";
import Presupuesto from "../../Presupuestos/domain/model/Presupuesto";
import LibroEstandarDetalleRepository from "../domain/LibroEstandarDetalleRepository";
import LibroEstandarRepository from "../domain/LibroEstandarRepository";
import LibroEstandar from "../domain/model/LibroEstandar";
import LibroEstandarDetalle from "../domain/model/LibroEstandarDetalle";
import LibroEstandarApiRepository from "../infrastructure/api/LibroEstandar.ApiRepository";
import LibroEstandarDetalleApiRepository from "../infrastructure/api/LibroEstandarDetalle.ApiRepository";

export interface LibroEstandarDetalleModalSectionProps {
	libroEstandar: LibroEstandar
	presupuesto: Presupuesto
	opened?: boolean
	oferta: Ofertas
	handleCloseModal: () => void
	handleRefresh: () => void
}

const LibroEstandarDetalleModalSection: React.FC<LibroEstandarDetalleModalSectionProps> = (
	{
		libroEstandar,
		presupuesto,
		opened,
		oferta,
		handleCloseModal,
		handleRefresh
	}
) => {

	const { getToken } = useContext(AuthContext);
	const { setLoading } = useLoading();
	const [estudios, setEstudios] = useState<Estudio[]>([]);
	const [indexSelected, setIndexSelected] = useState<number>();
	const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>();
	const [medidas, setMedidas] = useState<UnidadMedidaDto[]>([]);
	const [unidadMedida, setUnidadMedida] = useState<UnidadMedidaDto>();
	const [codigoObra, setCodigoObra] = useState<string>(oferta.codigoObra ?? '');
	const [libroEstandarSelected, setLibroEstandarSelected] = useState<LibroEstandar>({...libroEstandar, codigoObra:oferta.codigoObra});
	const [libroEstandarDetalleArray, setLibroEstandarDetalleArray] = useState<LibroEstandarDetalle[]>([]);
	const [libroEstandarDetalle, setLibroEstandarDetalle] = useState<LibroEstandarDetalle>({
		id: '',
		libroEstandarId: libroEstandar.id ?? '',
		codigoUnidad: '',
		descripcion: '',
		unidadMedida: {} as UnidadMedida,
		unidadMedidaId: '',
		gfh: 0,
		precioEjecucionMaterial: 0,
		coste: 0,
		medicion: 0,
		medicionFinal: 0

	});
	const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [deletionId, setDeletionId] = useState<string>();
	const [deletionIndex, setDeletionIndex] = useState<number>(-1);

	const editUnidadMedida = (unidadMedida: UnidadMedidaDto) => {
		const unidadObj: UnidadMedidaDto = medidas.find(((item: UnidadMedidaDto) => item.id === unidadMedida.id))!;
		setLibroEstandarDetalle({ ...libroEstandarDetalle, unidadMedidaId: unidadObj.id, unidadMedida: unidadObj as UnidadMedida });
		setUnidadMedida(unidadObj);
	}

	const fetchLibroEstandarDetalles = (id: string) => {
		const repo: LibroEstandarDetalleRepository = new LibroEstandarDetalleApiRepository(getToken());
		return repo.getByLibroEstandarId(id);
	};

	const fetchMedidas = useCallback(() => {
		const repo: UnidadesMedidaRepository = new UnidadesMedidaApiRepository(getToken());
		return repo.getConFiltro({}).then((data) => {
			setMedidas(data);
		})
			.catch(message => toast.error(message));
	}, []);

	useEffect(() => {
		fetchMedidas()
		if (libroEstandar.id) {
			fetchLibroEstandarDetalles(libroEstandar.id).then((resp => {
				setLibroEstandarDetalleArray(resp);
			}));
		}
	}, [libroEstandar]);

	useEffect(() => {
		fetchEstudios(presupuesto.id!)
	}, [])

	const updateLibroEstandarDetalle = (newValue: any, propertyValue: string) => {
		setLibroEstandarDetalle({ ...libroEstandarDetalle, [propertyValue]: newValue })
	}

	const updateLibroEstandar = (newValue: any, propertyValue: string) => {
		setLibroEstandarSelected({ ...libroEstandarSelected, [propertyValue]: newValue })
	}

	const fetchEstudios = useCallback((id: string) => {
		const repoEstudio: EstudioRepository = new EstudioApiRepository(getToken());
		const repoDetalle: LibroEstandarDetalleRepository = new LibroEstandarDetalleApiRepository(getToken());

		return repoEstudio.getByPresupuestoIdWithDiscount(id).then((estudiosList) => {
			let ledArray: LibroEstandarDetalle[] = libroEstandarDetalleArray
			const estudios = estudiosList.map((estudio, index) => {
				if (presupuesto.porOpciones === true) {
					if (presupuesto.estudioContratado === estudio.id) {
						estudio.estudioDetalle.sort((a,b) => a.descripcion.toLowerCase().charCodeAt(0) - b.descripcion.toLowerCase().charCodeAt(0)).map((estudioDetalle, index) => {
							let led = mapEstudioDetalleToLibroEstandarDetalle(estudioDetalle, index);
							ledArray.push(led);
						})
					}
				} else {
					estudio.estudioDetalle.sort((a,b) => a.descripcion.toLowerCase().charCodeAt(0) - b.descripcion.toLowerCase().charCodeAt(0)).map((estudioDetalle, index) => {
						let led = mapEstudioDetalleToLibroEstandarDetalle(estudioDetalle, index);
						ledArray.push(led);
					})
				}

				return { ...estudio, creationTime: estudio.creationTime ? formatDate2input(estudio.creationTime) : '' }
			});
			if (libroEstandar.id === "") {
				setLibroEstandarDetalleArray(ledArray);
			} else {
				repoDetalle.getByLibroEstandarId(libroEstandar.id!).then(libroDetallesResp => {
					setLibroEstandarSelected({ ...libroEstandarSelected, libroEstandarDetalle: libroDetallesResp })

					setLibroEstandarDetalleArray(libroDetallesResp)
					

				});
			}
			setEstudios(estudios);

		})
	}, [getToken]);


	const mapEstudioDetalleToLibroEstandarDetalle = (estudioDetalle: EstudioDetalle, index: number): LibroEstandarDetalle => {
		return new LibroEstandarDetalle({
			id: '',
			libroEstandarId: libroEstandar.id ?? '',
			codigoUnidad: "codigo - " + (index + 1),
			descripcion: estudioDetalle.descripcion,
			unidadMedidaId: estudioDetalle.unidadMedidaId,
			unidadMedida: estudioDetalle.unidadMedida,
			coste: +(estudioDetalle.precioCoste).toFixed(2),
			precioEjecucionMaterial: +(estudioDetalle.precioVenta).toFixed(2),
			gfh: 0,
			medicion: +medicionByUnidadMedida(estudioDetalle),
			medicionFinal: +medicionByUnidadMedida(estudioDetalle)
		})
	}

	const editarLibroEstandarDetalle = (libroEstandarDetalleEdit: LibroEstandarDetalle, index: number) => {
		setIndexSelected(index);
		setUnidadMedida(libroEstandarDetalleEdit.unidadMedida);
		setLibroEstandarDetalle(libroEstandarDetalleEdit);
	}

	const saveLibroEstandarDetalle = async (libroEstandarDetallesArray: LibroEstandarDetalle[]) => {
		const repo: LibroEstandarRepository = new LibroEstandarApiRepository(getToken());
		const repoDetalle: LibroEstandarDetalleRepository = new LibroEstandarDetalleApiRepository(getToken());

		if (libroEstandarDetalle.id != undefined && !validate(libroEstandarDetalle)) {
			toast.error("Complete los campos obligatorios para añadir un detalle al libro estandar");
			return;
		}

		if (libroEstandarDetallesArray.length == 0) {
			libroEstandarDetallesArray.push(libroEstandarDetalle);
		}

		if (libroEstandarSelected.id) {
			let libroEstandarDetalleList: LibroEstandarDetalle[] = [];

			libroEstandarDetallesArray.forEach((libroDetalle, index) => {
				if (libroDetalle.id) {//put
					setLoading(true)

					if (indexSelected != undefined && indexSelected >= 0) {
						if (indexSelected === index) {
							repoDetalle.update(libroEstandarDetalle).then(resp => {
								toast.success(`Detalle del Libro Estandar actualizado con éxito`);

								repoDetalle.getByLibroEstandarId(resp.libroEstandarId!).then(libroDetallesResp => {
									setLibroEstandarSelected({ ...libroEstandarSelected, libroEstandarDetalle: libroDetallesResp })

									setLibroEstandarDetalleArray(libroDetallesResp)
								});
								fetchLibroEstandarDetalles(libroEstandarSelected.id!)
									.then(resp => {
										setLibroEstandarDetalleArray(resp);
										setLibroEstandarDetalle({
											id: '',
											libroEstandarId: libroEstandarSelected.id ?? '',
											codigoUnidad: '',
											descripcion: '',
											unidadMedida: {} as UnidadMedida,
											unidadMedidaId: '',
											gfh: 0,
											precioEjecucionMaterial: 0,
											coste: 0,
											medicion: 0,
											medicionFinal: 0
										});
										setUnidadMedida({} as UnidadMedidaDto);
									}).finally(() => {
										setLoading(false);
									});
							});
						} else {
							repoDetalle.update(libroDetalle).then(resp => {
								if (libroDetalle.id === '' || libroDetalle.id === undefined) {
									toast.success(`Detalle del Libro Estandar actualizado con éxito`);
								}

								fetchLibroEstandarDetalles(libroEstandarSelected.id!)
									.then(resp => {
										setLibroEstandarDetalleArray(resp);
										setLibroEstandarDetalle({
											id: '',
											libroEstandarId: libroEstandarSelected.id ?? '',
											codigoUnidad: '',
											descripcion: '',
											unidadMedida: {} as UnidadMedida,
											unidadMedidaId: '',
											gfh: 0,
											precioEjecucionMaterial: 0,
											coste: 0,
											medicion: 0,
											medicionFinal: 0
										});
										setUnidadMedida({} as UnidadMedidaDto);
									}).finally(() => {
										setLoading(false);
									});
							});

						}
					}

				} else {

					libroEstandarDetalleList.push(libroEstandarDetalle);
				}
			});

			if (libroEstandarDetalleList && libroEstandarDetalleList.length > 0) {
				repoDetalle.addList(libroEstandarDetalleList).then(resp => {
					if (libroEstandarDetalleList[0].id !== "") {
						toast.success("Detalle del libro estandar añadido con éxito")
					}
					fetchLibroEstandarDetalles(libroEstandarSelected.id!).then(resp => {
						setLibroEstandarDetalleArray(resp);
						setLibroEstandarDetalle({
							id: '',
							libroEstandarId: libroEstandarSelected.id ?? '',
							codigoUnidad: '',
							descripcion: '',
							unidadMedida: {} as UnidadMedida,
							unidadMedidaId: '',
							gfh: 0,
							precioEjecucionMaterial: 0,
							coste: 0,
							medicion: 0,
							medicionFinal: 0
						});
						setUnidadMedida({} as UnidadMedidaDto);
					}).finally(() => {
						setLoading(false);
					});

				})
			}
		} else {
			//cuando no existe el libro Estandar
			try {
				setLoading(true);
				repo.add(libroEstandarSelected).then(resp => {
					let libroEstandarDetalleArrayUpdate: LibroEstandarDetalle[] = [];
					libroEstandarDetallesArray.forEach((leDetalle, index) => {
						const libroDetalle = { ...leDetalle, libroEstandarId: resp.id?.toString()! }
						const libroDetalleUpd = { ...libroEstandarDetalle, libroEstandarId: resp.id?.toString()! }

						if (libroEstandarDetalleArray && libroEstandarDetalleArray.length > 0) {
							if (indexSelected != undefined && indexSelected >= 0) {
								libroEstandarDetalleArrayUpdate = libroEstandarDetalleArray?.map((detalle, index) => {
									if (index === indexSelected) {
										return { ...detalle, ...libroDetalleUpd }
									} else {
										return { ...detalle, libroEstandarId: resp.id?.toString()! }
									}
								});
							} else {
								libroEstandarDetalleArrayUpdate = libroEstandarDetalleArray?.map((detalle, index) => {
									return { ...detalle, libroEstandarId: resp.id?.toString()! }
								});

								libroEstandarDetalleArrayUpdate.push(libroDetalle)
							}
						} else {
							libroEstandarDetalleArrayUpdate.push(libroDetalle)
						}
						toast.success(`Libro Estandar añadido con éxito`);
					})


					repoDetalle.addList(libroEstandarDetalleArrayUpdate).then(librosEstandarDetalleInserted => {
						repoDetalle.getByLibroEstandarId(resp.id!).then(libroDetallesResp => {
							setLibroEstandarSelected({
								id: resp.id,
								descripcion: resp.descripcion,
								articulo: resp.articulo,
								libroEstandarDetalle: libroDetallesResp,
								ofertaId: oferta.id,
								presupuestoId: presupuesto?.id!,
								codigoObra:resp.codigoObra
							})
							setLibroEstandarDetalleArray(libroDetallesResp)
							setLibroEstandarDetalle({
								id: '',
								libroEstandarId: libroEstandarSelected.id ?? '',
								codigoUnidad: '',
								descripcion: '',
								unidadMedida: {} as UnidadMedida,
								unidadMedidaId: '',
								gfh: 0,
								precioEjecucionMaterial: 0,
								coste: 0,
								medicion: 0,
								medicionFinal: 0
							});
							setUnidadMedida({} as UnidadMedidaDto);
						});
					});

					setIndexSelected(undefined);

				}).finally(() => {
					setLoading(false);
				});



			} catch (error) {
				console.error(error);
			}
		}
	}

	const saveLibroEstandar = async () => {
		const repo: LibroEstandarRepository = new LibroEstandarApiRepository(getToken());
		const repoDetalle: LibroEstandarDetalleRepository = new LibroEstandarDetalleApiRepository(getToken());

		if (libroEstandarSelected.id) {
			try {
				setLoading(true);
				if (libroEstandarSelected.descripcion === "" || libroEstandarSelected.descripcion == null || libroEstandarSelected === undefined) {
					toast.warning("La descripción de Libro Estandar no puede estar vacia")
					setLoading(false);
					return
				}
				repo.update(libroEstandarSelected).finally(() => {
					setLoading(false);
					setLibroEstandarDetalle(({
						id: '',
						libroEstandarId: libroEstandar.id ?? '',
						codigoUnidad: '',
						descripcion: '',
						unidadMedida: {} as UnidadMedida,
						unidadMedidaId: '',
						gfh: 0,
						precioEjecucionMaterial: 0,
						coste: 0,
						medicion: 0,
						medicionFinal: 0
				
					}));
					//handleRefresh();
				});
				toast.success(`Libro Estandar actualizado con éxito`);


			} catch (err) {
				console.error(err)
			}

		} else {
			try {
				setLoading(true);

				if (libroEstandarSelected.descripcion === "" || libroEstandarSelected.descripcion == null || libroEstandarSelected === undefined) {
					toast.warning("La descripción de Libro Estandar no puede estar vacía")
					setLoading(false);
					return
				}
				const resp = await repo.add(libroEstandarSelected)
					.finally(() => {
						setLoading(false);
					});

				setLibroEstandarSelected({ ...libroEstandarSelected, id: resp.id })

				const libroEstandarDetalleUpdated = libroEstandarDetalleArray?.map((detalle) => {
					return { ...detalle, libroEstandarId: resp.id }
				})

				// setLibroEstandarDetalleArray(libroEstandarDetalleUpdated);

				libroEstandarDetalleUpdated?.forEach((detalle) => {
					repoDetalle.add({ ...detalle, libroEstandarId: resp.id! });
				})
				repoDetalle.getByLibroEstandarId(resp.id!).then(libroDetallesResp => {
					setLibroEstandarSelected({
						id: resp.id,
						descripcion: resp.descripcion,
						articulo: resp.articulo,
						libroEstandarDetalle: libroDetallesResp,
						ofertaId: oferta.id,
						presupuestoId: presupuesto?.id!,
						codigoObra:resp.codigoObra,
					})

					setLibroEstandarDetalleArray(libroDetallesResp);
				})

				setLibroEstandarDetalle(({
					id: '',
					libroEstandarId: resp.id ?? '',
					codigoUnidad: '',
					descripcion: '',
					unidadMedida: {} as UnidadMedida,
					unidadMedidaId: '',
					gfh: 0,
					precioEjecucionMaterial: 0,
					coste: 0,
					medicion: 0,
					medicionFinal: 0
			
				}));
				toast.success(`Libro Estandar añadido con éxito`);
				//handleRefresh();
			} catch (err) {
				console.error(err)
			}
		}
	}

	//delete
	const openConfirmationModalDelete = (id: string, index: number) => {
		setDeletionId(id);
		setDeletionIndex(index);
		setOpenDelete(true);
	}

    const handleCloseModalDelete = (remove: boolean) => {
        setOpenDelete(false);
        if (remove) {
            eliminarLibroEstandarDetalle(deletionId!, deletionIndex!);
        }
    }

	const eliminarLibroEstandarDetalle = (id: string, index: number) => {
		const repoDetalle: LibroEstandarDetalleRepository = new LibroEstandarDetalleApiRepository(getToken());

		if (!libroEstandarDetalleArray[index].id) {
			setLibroEstandarDetalleArray(libroEstandarDetalleArray.filter((x, i) => i !== index));
			toast.success(`Eliminado correctamente`);
			return;
		}

		repoDetalle.delete(id).then(resp => {
			toast.success('Eliminado con éxito');
			fetchLibroEstandarDetalles(libroEstandar.id!).then((resp => {
				setLibroEstandarDetalleArray(resp);
			}));
		})
			.catch((error) => {
				toast.error(`Error en la eliminación:${error}`);
			});
	}

	function validate(data: LibroEstandarDetalle): boolean {
		let errors: FormErrors<LibroEstandarDetalle> = {};
		const fieldIsRequired = "Campo obligatorio.";
		if (data.descripcion === undefined || data.descripcion == "") {
			errors = { ...errors, descripcion: fieldIsRequired };
		}
		if (data.medicion < 0) {
			errors = { ...errors, medicion: fieldIsRequired }
		}
		if (data.codigoUnidad <= "") {
			errors = { ...errors, codigoUnidad: fieldIsRequired }
		}
		if (data.medicionFinal < 0) {
			errors = { ...errors, medicionFinal: fieldIsRequired }
		}
		if (data.coste < 0) {
			errors = { ...errors, coste: fieldIsRequired }
		}
		if (data.precioEjecucionMaterial < 0) {
			errors = { ...errors, precioEjecucionMaterial: fieldIsRequired }
		}
		if (data.unidadMedidaId <= "") {
			errors = { ...errors, unidadMedidaId: fieldIsRequired }
		}

		return Object.keys(errors).length == 0;
	}


	const generarLibroEstandar=()=>{
		const repo: LibroEstandarRepository = new LibroEstandarApiRepository(getToken());
		setLoading(true);
		repo.autoGenerarLibroEstandar(oferta.id).then((response)=>{
			setLibroEstandarSelected(response);
			toast.success("Libro estándar autogenerado con éxito");
			setLoading(false);
		});
	}


	const traspasarLibroEstandar=()=>{
		const repo: LibroEstandarRepository = new LibroEstandarApiRepository(getToken());
		setLoading(true);
		repo.update(libroEstandarSelected).then((resp)=>{
			repo.traspasarLibroEstandar(oferta.id).then((response)=>{
				toast.success("Libro estándar traspasado con éxito");
			}).finally(() => {
				setLoading(false);
			});
		})		
	}


	return (
		// asdf
		<div>
			  <Modal
				className="filters-modal modal-l"
				show={openConfirmationModal}
				onHide={() => setOpenConfirmationModal(false)}>
				<ModalHeader>
					<ModalTitle>
						Generar Libro Estándar
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<p>Se va a generar el Libro Estándar y perderá los datos actuales</p>
					<p>¿Está seguro?</p>
					<div className="bottom-buttons">
						<CustomButton
							key={`removeCancelModal`}
							title={'Cancelar'}
							className={'btn-primary'}
							onButtonClick={() => { setOpenConfirmationModal(false); }}
							text="No"
						/>
						<CustomButton
							key={`confirmDeleteModal`}
							title={'Generar'}
							className={'btn-primary'}
							btnStyle="delete"
							onButtonClick={() => { generarLibroEstandar(); setOpenConfirmationModal(false)}}
							text="Sí"
						/>
					</div>

				</ModalBody>
			</Modal>
			<ModalPage
				handleCloseModal={handleCloseModal}
				opened={opened}
				fields={
					<>
						<div className="padding-bg">
							<div className="double-col-grid">
								<FormInput
									label="Código Obra"
									value={codigoObra}
									onChange={(e) => {setCodigoObra(e.target.value); updateLibroEstandar(e.target.value, 'codigoObra')}}
									disabled={oferta.codigoObra && oferta.codigoObra != "" ? true : false}
									required
								/>
							</div>
							<div className="double-col-grid">
								<FormInput
									maxLength={15}
									label="Artículo"
									value={libroEstandarSelected.articulo}
									onChange={(e) => updateLibroEstandar(e.target.value, 'articulo')}
									required
								/>
								<FormInput
									maxLength={40}
									label="Descripción"
									value={libroEstandarSelected.descripcion}
									onChange={(e) => updateLibroEstandar(e.target.value, 'descripcion')}
									required
								/>

							</div>
						</div>

						{libroEstandar !== null &&
							<div className="padding-bg accent-bg">
								<div className="study-detail">

									<h3>Detalle del Libro Estándar
										{libroEstandarDetalle.id != '' ? " (Editando detalle)" : " (Creando detalle)"}
									</h3>

								</div>

								<div className="row-of-three">
									<FormInput
										label="Código"
										value={libroEstandarDetalle.codigoUnidad}
										onChange={(e) => { updateLibroEstandarDetalle(e.target.value, 'codigoUnidad') }}
										required
									/>
									<div className="input-with-btn">
										<FormField<DropdownProps>
											label="U. Medida"
											options={medidas.map(
												(element) => {
													return {
														text: element.descripcion,
														id: element.id,
														selected: unidadMedida?.id == element.id ? true : false,
														onClick: () => { editUnidadMedida(element); }
													}
												})}
											required={true}
											disabled={false}
											singleSelection={true}
											component={Dropdown}
										/>
									</div>
									<FormInput
										label="Descripción"
										value={libroEstandarDetalle.descripcion}
										onChange={(e) => updateLibroEstandarDetalle(e.target.value, 'descripcion')}
										required
									/>
								</div>

								<div className="row-of-three">
									<FormInput
										label="GFH"
										value={libroEstandarDetalle.gfh.toString()}
										onChange={(e) => { updateLibroEstandarDetalle(e.target.value, 'gfh') }}
										type="text"
										required
									/>
									<FormInput
										label="Coste Planificación"
										type="number"
										value={libroEstandarDetalle.coste.toString()}
										onChange={(e) => { updateLibroEstandarDetalle(e.target.value, 'coste') }}
									/>

									<FormInput
										label="PEM"
										value={libroEstandarDetalle.precioEjecucionMaterial.toString()}
										onChange={(e) => { updateLibroEstandarDetalle(e.target.value, 'precioEjecucionMaterial') }}
										type="number"
									/>
								</div>
								<div className="double-col-grid">
									<FormInput
										label="Medición Proyecto"
										value={libroEstandarDetalle.medicion.toString()}
										onChange={(e) => { updateLibroEstandarDetalle(e.target.value, 'medicion') }}
										type="number"
									/>
									<FormInput
										label="Medición Final"
										value={libroEstandarDetalle.medicionFinal.toString()}
										onChange={(e) => { updateLibroEstandarDetalle(e.target.value, 'medicionFinal') }}
										type="number"
									/>
								</div>

								<div className="buttons-right">
									<CustomButton
										key={`confirmAddDetalleModal`}
										text="Guardar"
										title={'Añadir detalle'}
										className={'btn-primary'}
										btnStyle="accept"
										onButtonClick={() => { saveLibroEstandarDetalle([libroEstandarDetalle]); }}
										type="button"
										disabled={(libroEstandarDetalle.codigoUnidad == ''
											|| libroEstandarDetalle.medicion < 0
											|| libroEstandarDetalle.medicionFinal < 0
											|| libroEstandarDetalle.descripcion == "")}
									/>
								</div>
							</div>
						}

						<div className="padding-bg">
							<h3>Descripción del Libro Estándar</h3>
							<table className="mini-table">
								<thead>
									<tr className='table-header'>
										<th>Código</th>
										<th>Uds</th>
										<th>Descripción</th>
										<th>G.F.H</th>
										<th>Coste Planificación</th>
										<th>P.E.M</th>
										<th>Medición Proyecto</th>
										<th>Medición Final</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{libroEstandarDetalleArray && libroEstandarDetalleArray.map((item: LibroEstandarDetalle, index: number) => (
										<tr key={index}>
											<td>{item.codigoUnidad}</td>
											<td>{item.unidadMedida.descripcion}</td>
											<td>{item.descripcion}</td>
											<td>{item.gfh}</td>
											<td>{formatNumber(item.coste)}</td>
											<td>{formatNumber(item.precioEjecucionMaterial)}</td>
											<td>{formatNumber(item.medicion)}</td>
											<td>{formatNumber(item.medicionFinal)}</td>
											<td>
												<div className="icons-horizontal">
													<IconButton
														icon="edit"
														onClick={() => { editarLibroEstandarDetalle(item, index); }}
													/>
													<IconButton
														icon="delete"
														onClick={() => { openConfirmationModalDelete(item.id!, index); }}
													/>
												</div>
											</td>

										</tr>
									))}
								</tbody>
							</table>
						</div>
						<div className="buttons-right">
							<CustomButton
								key={`confirmSaveLibro`}
								title={libroEstandarDetalleArray.length == 0 ? 'Complete el libro estándar para poder generarlo' : 'Crear L.E.'}
								// className={'contact-btn'}
								className={'btn-primary'}
								btnStyle="accept"
								onButtonClick={() => { saveLibroEstandar(); }}
								type="button"
								text= {!libroEstandarSelected.id || libroEstandarSelected.id == '' ? "Generar Libro Estándar" : "Actualizar Libro Estándar"}
								disabled={libroEstandarDetalleArray.length == 0 || libroEstandarSelected.descripcion == "" || libroEstandarSelected.descripcion == null || libroEstandarSelected == undefined}
							/>

							<CustomButton title="Traspasar Libro Estándar"
								text="Traspasar Libro estándar"
								onButtonClick={()=>traspasarLibroEstandar()}
								disabled={(libroEstandarSelected?.codigoObra=='' || libroEstandarSelected?.codigoObra?.length!=8 || !libroEstandarSelected.id || libroEstandarSelected.id == '')}>
							</CustomButton>
						</div>
					</>

				}

				modalTitle={libroEstandar.id ? "Editar Libro Estándar" : "Añadir Libro Estándar"}
				modalDisable={libroEstandar.id === undefined || libroEstandar.id === null ? true : false}
				bodyClassName='--large'
			>
			</ModalPage>
			{
                openDelete &&
                <DeleteModal
                    open={openDelete}
                    handleCloseModal={handleCloseModalDelete}
					field="descripción del libro estandar"
                >
                </DeleteModal>
            }
		</div>

		

	)
}

export default LibroEstandarDetalleModalSection;