import { useContext, useEffect, useState } from "react";
import FilterModal from "../../../../../components/ui/atoms/FilterModal/FilterModal";
import { SelectorType } from "../../../../../components/ui/atoms/SuggestionInput/hooks/useSelectorItems";
import { GlobalContext, GlobalContextType } from "../../../../../context/Global.Context";
import { AuthContext } from "../../../../Login/AuthContextProvider";
import FechaPresupuestoDateSelector from "../../../../Oportunidades/views/atoms/FechaPresupuesto.DateSelector";
import FechaRegistroDateSelector from "../../../../Oportunidades/views/atoms/FechaRegistro.DateSelector";
import ClienteSuggestionInput from "../../../../Oportunidades/views/atoms/SuggestionInputs/Cliente.SuggestionInput";
import ProvinciaSuggestionInput from "../../../../Oportunidades/views/atoms/SuggestionInputs/Provincia.SuggestionInput";
import { OfertasTableData } from "../../../context/Ofertas.Context";
import OfertasFilterFieldsProvider from "../../../context/Ofertas.FilterFields.Provider";
import useFilterFields from "../../../hooks/useFilterFields";
import ComercialDropdown from "../../atoms/DropDowns/Comerciales.Dropdown";
import EstadoOfertasDropdown from "../../atoms/DropDowns/EstadoOfertas.Dropdown";
import OfertasDelegacionesDropdown from "../../atoms/DropDowns/OfertasDelegacionesDropdown";
import TipoOfertasDropdown from "../../atoms/DropDowns/TipoOfertas.Dropdown";
import IdOfertaInput from "../../atoms/Filters/IdOfertaInput";
import LicitacionOfertasInput from "../../atoms/Filters/LicitacionOfertasInput";
import ObraDescripcionInput from "../../atoms/Filters/ObraDescripcionInput";
import OportunidadCodigoInput from "../../atoms/Filters/OportunidadCodigoInput";
import PromotorSuggestionInput from "../../atoms/SuggestionInputs/Promotor.SuggestionInput";
import ToneladasMinMax from "../../atoms/Toneladas.MinMax";
import OfertasTagCloud from "../../molecules/Ofertas.TagCloud";
import "./Ofertas.FilterSection.scss";
import CodigoPostalSuggestionInput from "../../atoms/SuggestionInputs/CodigoPostal.SuggestionInput";
import CodigoObraInput from "../../atoms/Filters/CodigoObraInput";

const OfertasFilterSection: React.FC<{}> = () => {
	const { downloadExcel } = useContext(OfertasTableData);	

	const { globalState, updateGlobalState } = useContext(
		GlobalContext
	  ) as GlobalContextType;
	  const { getDelegacion } = useContext(AuthContext);
	  const componentName = "ofertas";
	  const [isChecked, setIsChecked] = useState<boolean>(globalState[componentName]?.filters ? globalState[componentName]?.filters?.isChecked : true);

	  const onCheckChange = async () => {
		const checked = !isChecked;
		setIsChecked(checked);
	
		updateGlobalState(componentName, {
		  filters: {
			...globalState[componentName]?.filters,
			isChecked: checked,
		  },
		  pagination: {
			skipCount: 0,
		  },
		});
	  };
	  const {
		delegacionesFilter
	} = useFilterFields();
	
	  const handleChange=(listado:SelectorType[])=>{

		updateGlobalState(componentName, {
		  filters: {
			...globalState[componentName]?.filters,
			isChecked: isChecked,
			delegacionFilterIds:listado,
		  },
		  pagination: {
			skipCount: 0,
		  },
		});
	  }

	  useEffect(() => {
		if((globalState[componentName]?.filters?.isChecked==undefined || globalState[componentName]?.filters?.isChecked==false) && delegacionesFilter.options.filter(p=>p.selected==true)?.length==0 )
		{
			updateGlobalState(componentName, {
				filters: {
				  ...globalState[componentName]?.filters,
				  isChecked: isChecked,
				},
				pagination: {
				  skipCount: 0,
				},
			  });
		}
	  }, [])

	return (
		<>
		<OfertasFilterFieldsProvider handleUpdateFilter={handleChange}>
			<div className="filter-section">
				<div className="filters-modal-container">
					<IdOfertaInput idTest="CodOfertaFiltroOut"/>
					<FilterModal filters={
						<>
							<ProvinciaSuggestionInput />
							{(globalState.ofertas?.filters?.isChecked==false || globalState.ofertas?.filters?.isChecked==undefined) && <OfertasDelegacionesDropdown idTest="DelegacionFiltro"/>}
							
							<ClienteSuggestionInput disabled={false} idTest="ClienteOfertaFiltro" />
							<FechaRegistroDateSelector idTest="FechaGrabacionFiltro"/>
							<TipoOfertasDropdown idTest="TipoOfertaFiltro"/>
							<FechaPresupuestoDateSelector idTest="FechaPresupuestoOfertaFiltro"/>
							<EstadoOfertasDropdown idTest="EstadoOfertaFiltro"/>
							<ToneladasMinMax idTestMin="MinToneladasOfertaFiltro" idTestMax="MaxToneladasOfertaFiltro"/>
							<ComercialDropdown idTest="ComercialFiltro"/>
							<PromotorSuggestionInput idTest="PromotorOfertaFiltro"/>
							<CodigoPostalSuggestionInput idTest="CodigoPostalOfertaFiltro"/>
							<ObraDescripcionInput idTest="ObraOfertaFiltro"/>
							<LicitacionOfertasInput idTest="LicitacionOfertaFiltro"/>
							<IdOfertaInput idTest="CodOfertaFiltro"/>
							<OportunidadCodigoInput idTest="OportunidadOfertaFiltro"/>
							<CodigoObraInput idTest="CodigoObraFiltro"/>
						</>
					}
						titleShow="Filtros"
						modalTitle="Selección de filtros"
						//modalSubtitle="Introduzca los parámetros para filtrar"
						downloadExcel={downloadExcel}
						label = {"TituloModal"}
					>
					</FilterModal>
					 <label>Mis datos</label>
					<input
					checked={isChecked}
					onChange={onCheckChange}
					type="checkbox"
					data-cy="MisDatosCheckbox"
					disabled={delegacionesFilter.options.filter(p=>p.selected==true)?.length>0}
					/>

				</div>
				<OfertasTagCloud />
			</div>
		</OfertasFilterFieldsProvider>
		</>
	)
}

export default OfertasFilterSection;