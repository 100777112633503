import { SelectableItem } from '@pavabits/components';
import { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Constantes from '../../../assets/constants/Constantes';
import FormTemplate from '../../../components/common/FormTemplate/FormTemplate';
import Dropdown, { DropdownProps } from '../../../components/ui/atoms/Dropdown/Dropdown';
import SingleSuggestionInputField, { SingleSuggestionInputFieldProps } from '../../../components/ui/atoms/SingleSuggestionInput/SingleSuggestionInput.Field';
import FormField from '../../../components/ui/molecules/Form-field/FormField';
import { useLoading } from '../../../context/Loading.Context';
import CustomInput from '../../../shared/components/Inputs/CustomInput/CustomInput';
import { fechaValida, formatDate2String, formatDDMMYYYY, formatYYYYMMDD } from '../../../utils/DateUtil';
import ContactosRepository from '../../Clientes/domain/ContactosRepository';
import ContactosCliente from '../../Clientes/domain/model/ContactosCliente';
import SeguimientoCliente from '../../Clientes/domain/model/SeguimientoCliente';
import TipoSeguimientoCliente from '../../Clientes/domain/model/TipoSeguimientoCliente';
import TipoSeguimientoClienteRepository from '../../Clientes/domain/TipoSeguimientoClienteRepository';
import ContactosClientesApiRepository from '../../Clientes/infraestructure/api/ContactosClientes.ApiRepository';
import TipoSeguimientoClienteApiRepository from '../../Clientes/infraestructure/api/TipoSeguimientoCliente.ApiRepository.';
import { AuthContext } from '../../Login/AuthContextProvider';
import OfertaSelectable from '../../Ofertas/domain/model/OfertaSelectable';
import OfertasRepository from '../../Ofertas/domain/OfertasRepository';
import OfertasApiRepository from '../../Ofertas/infraestructure/api/Ofertas.ApiRepository';
import ClienteRepository from '../../Oportunidades/domain/ClienteRepository';
import ClienteApiRepository from '../../Oportunidades/infraestructure/api/Cliente.ApiRepository';
import SeguimientoHistoricoClienteRepository from '../domain/SeguimientoHistoricoClienteRepository';
import SeguimientoHistoricoClienteApiRepository from '../infrastructure/api/SeguimientoHistoricoClienteApiRepository';

const ClientesSeguimientoEdicion = () => {
  const location = useLocation();
  const url= location.pathname.split("/")
  const id= url[url.length-1];	
  const formId = "seguimiento-historico-clientes-form";
  const [optionsTipoSeguimientoCliente, setOptionsTipoSeguimientoCliente] = useState<TipoSeguimientoCliente[]>([]);
  const { getToken, username } = useContext(AuthContext);
  const tipoSeguimientoClienteSelected = { id: '0', descripcion: "Seleccionar" } as SelectableItem;
  const [contactos, setContactos] = useState<ContactosCliente[]>([])
  const history = useHistory();
  const {setLoading} = useLoading();
  const [showFields, setShowFields] = useState<boolean>(false);
  const repo: SeguimientoHistoricoClienteRepository = new SeguimientoHistoricoClienteApiRepository(getToken());
  const [errores, setErrores] = useState<string[]>([]);
  const [ofertasSelectable, setOfertasSelectable] = useState<OfertaSelectable[]>([]);
  const [seguimiento, setSeguimiento] = useState<SeguimientoCliente>({
    id: "",
    fechaSeguimiento: "",
    observaciones:"",
    clienteId: undefined,
    tipoSeguimientoClienteId: 1,
    estadoOfertaId: 1,
    lastModificationTime:"",
    contactoId:"",
    ofertaId: "",
    seguimientoOfertaId:"",
    clienteNombre: "",
    ofertaCodigo: "",
    ofertaDescripcion: "",
    ofertaToneladas: ""
})

  useEffect(() => {
    fetchTipoSeguimiento()
  }, [])

  useEffect(() => {
    fetchSeguimiento(id)
  }, [id]);

  const fetchSeguimiento = useCallback((id: string) => {
    setLoading(true);
    return repo.getById(id)
    .then((data) => {
        setSeguimiento({...seguimiento, 
          id: data.id, 
          fechaSeguimiento: formatDate2String(formatDDMMYYYY(data.fechaSeguimiento.split('T')[0])),
          observaciones: data.observaciones,
          clienteId: data.clienteId,
          tipoSeguimientoClienteId: data.tipoSeguimientoClienteId,
          estadoOfertaId: data.estadoOfertaId,
          lastModificationTime: data.lastModificationTime,
          contactoId: data.contactoId,
          ofertaId: data.ofertaId,
          seguimientoOfertaId: data.seguimientoOfertaId,
          clienteNombre: data.clienteNombre,
          ofertaCodigo: data.ofertaCodigo,
          ofertaDescripcion: data.ofertaDescripcion,
          ofertaToneladas: data.ofertaToneladas
  })
    })
    .catch(message => toast.error(message))
  }, [getToken()])

  useEffect(() => {
    setContactos([]);
    if (seguimiento.clienteId) {
      fetchContactos()
    }

    if (seguimiento.ofertaId) {
      fetchOfertas()
    }
  }, [seguimiento.clienteId, seguimiento.ofertaId])

  const fetchTipoSeguimiento = useCallback(
    () => {
        const repo: TipoSeguimientoClienteRepository = new TipoSeguimientoClienteApiRepository(getToken());
        return repo.getAll().then((resp: TipoSeguimientoCliente[]) => {
            setOptionsTipoSeguimientoCliente(resp);
        })
    }
    , [getToken, tipoSeguimientoClienteSelected]);

    const fetchOfertas = useCallback((id?: string) => {
      const ofertasRepo: OfertasRepository = new OfertasApiRepository(getToken());

      return ofertasRepo.fetchOfertasByFilterSelectable({
        "filter": {
          codigo: seguimiento.ofertaCodigo
        },
        "sortingCriteria": "",
        "maxResultCount": 0,
        "skipCount": 0
        }).then((resp) => {
          setOfertasSelectable(resp);
          return resp;
        }).finally(() => {
          setLoading(false);
          setShowFields(true);
        });
    }
      , [getToken, seguimiento.ofertaId]);

      const fetchContactos = useCallback((id?: string) => {
        const repo: ContactosRepository = new ContactosClientesApiRepository(getToken());
        setLoading(true);
        return repo.getByClienteId(seguimiento.clienteId as number)
          .then((items) => {
            setContactos(items);
          })
          .finally(() => {
            setLoading(false);
            setShowFields(true);
          });
      }
        , [getToken, seguimiento.clienteId]);

    const handleSelectTipo = (tipoSeguimiento: TipoSeguimientoCliente) => {
        const seguimientoObj: TipoSeguimientoCliente | undefined =  optionsTipoSeguimientoCliente.find(((item: any) => item.id === tipoSeguimiento.id));
    if (seguimientoObj) {
      setSeguimiento((prev: any) => ({...prev, tipoSeguimientoClienteId: seguimientoObj.id }))
    }
  }

  const searchCliente = useCallback((search: string) => {
    const repoCliente: ClienteRepository = new ClienteApiRepository(getToken());
    return repoCliente.searchCliente({ filter: { descripcion: search} })
  }, [getToken])

  const searchOfertas = useCallback(
    (search: string) => {
    const ofertasRepo: OfertasRepository = new OfertasApiRepository(getToken());

    if (search.search(" - "))
    {
      search = search.split(" - ")[0];
    }

    return ofertasRepo.fetchOfertasByFilterSelectable({
      "filter": {
        codigo: isNaN(+search) ? undefined : search,
        obraDescripcion: search,
        clienteIds:seguimiento.clienteId?[+seguimiento.clienteId]:[]
      },
      "sortingCriteria": "",
      "maxResultCount": 0,
      "skipCount": 0
      }).then((resp) => {
        setOfertasSelectable(resp);
        return resp;
      });   
  }
    , [getToken, seguimiento.clienteId]);

  const handleFechaChange = (newValue: string) => {
    setSeguimiento((prev: any) => ({...prev, fechaSeguimiento: newValue}))
  }

  const handlePropertyChange = (property: string, newValue: string | number) => {
    setSeguimiento((prev: any) => ({...prev, [property]: newValue, Usuario: username}));
}

const handleClienteChange = (newValue: string) => {
  setSeguimiento((prev: any) => ({...prev, clienteId: newValue != "" ? Number(newValue) : undefined,
                                           contactoId: undefined, 
                                           contactoNombre: undefined,
                                           ofertaId:"", 
                                           ofertaDescripcion:"",
                                           estadoOfertaId:undefined,
                                          ofertaToneladas:undefined}))
}

const handleOfertaChange = (newValue: string) => {
  setSeguimiento((prev: any) => ({...prev, ofertaId: newValue[0], 
                                           ofertaToneladas: ofertasSelectable.find(x => x.id == newValue) ? 
                                           ofertasSelectable.find(x => x.id == newValue)?.toneladas.toString() :
                                           "Sin toneladas"}))
}

const seguimientoClienteValidation = (): boolean => {
  const errores: string[] = [];

  if (seguimiento.tipoSeguimientoClienteId < 0) errores[0] = 'El tipo tiene que estar seleccionado';
  if (seguimiento.clienteId == undefined) errores[1] = 'El cliente no puede estar vacío';
  if (!fechaValida(seguimiento.fechaSeguimiento)) errores[2] = 'La fecha no es valida';
  if (seguimiento.observaciones && seguimiento.observaciones?.length > Constantes.OBSERVACIONES_MAX_LENGTH) {
      errores[3] = 'Máximo 200 caracteres.';
  }
  setErrores(errores);
  return errores.length === 0;
}

const updateSeguimiento = (data: any) => {
  repo.update({...seguimiento, fechaSeguimiento: formatYYYYMMDD(seguimiento.fechaSeguimiento)} as SeguimientoCliente)
  .then((resp) => {
    toast.success(`Seguimiento actualizado con éxito`);
    setSeguimiento({...resp, fechaSeguimiento: formatDate2String(formatDDMMYYYY(resp.fechaSeguimiento.split('T')[0]))})
  })
  .catch(() => {
    toast.error(`Error al actualizar el seguimiento`);
});
}

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (seguimientoClienteValidation()) {
      updateSeguimiento(seguimiento);
    }
  }

  return (
    <div className='add-edit-view-container'>
    <div className='add-edit-header'>
    <h4>Editar Seguimiento de Clientes</h4>
    </div>
    <div className="add-edit-body">
    <FormTemplate
        formId={formId}
        onSubmit={handleSubmit}
        >
       <div className="row-of-four">
       {optionsTipoSeguimientoCliente &&
          <FormField<DropdownProps>
            label="Tipo"
            options={optionsTipoSeguimientoCliente.filter(x => x.visible).map(
              (element) => {
                return {
                  text: element.descripcion,
                  id: element.id,
                  selected: seguimiento.tipoSeguimientoClienteId == +element.id ? true : false,
                  onClick: () => {handleSelectTipo(element)}
                }
              })}
            idTest='TipoClienteSeguimientoHistorico'
            required={true}
            disabled={false}
            singleSelection={true}
            component={Dropdown}
            error={errores[0]}
          />
        }

        {showFields && <FormField<SingleSuggestionInputFieldProps>
							label="Cliente"
							value={{ id: seguimiento.clienteId?.toString() ?? "", 
                      text: seguimiento.clienteNombre ?? ""}}
							onChange={handleClienteChange}
							searchCallback={(searchCliente)}
							component={SingleSuggestionInputField}
              required
              error={errores[1]}
              idTest="NombreClienteSeguimientoHistorico"
						/>}

        {contactos && seguimiento.id && <FormField<DropdownProps>
          label="Contactos"
          options={contactos!.map(
            (element) => {
              return {
                text: element.personaContacto,
                id: element.id,
                selected: seguimiento.contactoId == element.id ? true : false,
                onClick: () => { handlePropertyChange("contactoId", element.id); }
              }
              })}
          idTest='ContactosClienteSeguimientoHistorico'
          disabled={seguimiento.clienteId === null}
          singleSelection
          component={Dropdown}
        />}

         <CustomInput
            value={seguimiento.fechaSeguimiento}
            onChange={(e) => {handleFechaChange(e)}}
            formClassName={"secondary-input"}
            type={'date'}
            label="Fecha"
            error={errores[2]}
            idTest='FechaClienteSeguimientoHistorico'
          />

          {showFields &&
          <FormField<SingleSuggestionInputFieldProps>
							label="Oferta"
							value={seguimiento.clienteId?
                     { id: seguimiento.ofertaId?.toString() ?? "", 
                      text: seguimiento.ofertaDescripcion ?? ""}
                      :
                      { id:  "", 
                        text: ""}
                    }
							onChange={handleOfertaChange}
							searchCallback={(searchOfertas)}
							component={SingleSuggestionInputField}
              disabled={seguimiento.clienteId==undefined}
              idTest="Oferta"
						/>
            }

            {showFields && <CustomInput
              value={seguimiento.ofertaToneladas ? seguimiento.ofertaToneladas.toString() : "Sin toneladas"}
              onChange={(e) => {}}
              formClassName={"secondary-input"}
              label="Toneladas"
              idTest='Toneladas'
              disabled
            />
            }

          <CustomInput
            value={seguimiento.observaciones}
             onChange={(e) => { handlePropertyChange('observaciones', e); }}
            label="Observaciones"
            idTest="ObservacionesClienteSeguimientoHistorico"
            error={errores[3]}/>
        </div>
        </FormTemplate>

    </div>
    </div>
  )
}

export default ClientesSeguimientoEdicion