import { FC, useContext, useEffect, useState } from "react";
import { OverlayTrigger, Popover, PopoverHeader } from "react-bootstrap";
import PaginationComponent from "../../../../components/ui/molecules/Pagination/PaginationComponent";
import { OportunidadesDataContext, OportunidadesPagination, OportunidadesSortingContext } from "../../context/Oportunidades.Context";
import Oportunidad from "../../domain/model/Oportunidad";
import LicitacionesGrid from "./Licitaciones.Grid";
import OportunidadesRow from "./Oportunidades.Row";
// import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import '../../../../../src/assets/styles/elements/_table.scss';
import { GlobalContext, GlobalContextType } from "../../../../context/Global.Context";
import { Icon } from "../../../../shared/components/Icon/Icon";

import TableGridView from "../../../../components/TableGridView";
import SortingArrows from "../../../../components/ui/molecules/Sorting/SortingArrows";
import { AuthContext } from "../../../Login/AuthContextProvider";
import Adjudicacion from "../../domain/model/Adjudicacion";


const OportunidadesGrid: FC<{}> = () => {

	const { columns, data, sumaToneladas } = useContext(OportunidadesDataContext);
	const { onSort, onPageSelect, onSetMaxCount, onPageBackwards, sorting } = useContext(OportunidadesPagination);
	const { maxCount } = useContext(OportunidadesSortingContext);
	const { globalState } = useContext(GlobalContext) as GlobalContextType;
	const [allExpanded, setAllExpanded] = useState<boolean>(false);
	const { userPreferences} = useContext(AuthContext);
	const componentName = "oportunidades";

	const CellTooltip = (id: string) => {
		const dataItem = data.items.find(item => item.id === id);
		return AdjudicacionesPopover(dataItem!)
	}

	const rowMapper: OportunidadesRow = new OportunidadesRow(undefined, [
		{
			key: "contratistaDescripcion",
			childRender: CellTooltip
		}
	]);

	const RowExpansion = (id: string) => {

		const dataItem = data.items.find(item => item.id === id);
		const filter:Adjudicacion[]= dataItem?.adjudicaciones.filter(adj => adj.contratista.id=="1")??[];

		return <LicitacionesGrid id={id} oportunidadIdAjuste={filter} />
	}

	const scrollToTop = () => {
		const element = document.getElementById('gridDiv');
		element && //element.scrollTo(0, 0);
			element.scrollTo({
				top: 0,
				behavior: "smooth",
			});
	}
	
	useEffect(() => {
		scrollToTop();
	}, [data.items]);

	const toggleExpandAll = () => {
		setAllExpanded(!allExpanded)
	}
	
	return (
			<>
			{ userPreferences &&
				<>
					<div className="download-excel">
						<button onClick={toggleExpandAll} className="expand-btn">
												<Icon icon={!allExpanded ? "expand_more" : "expand_less"} idCypress="DesplegarTodasOportunidades"/>
											</button>			
					</div>
					
					<div className="grid-container" id='gridDiv'>
						<TableGridView
							data={data.items}
							columns={columns}
							sumaToneladas={sumaToneladas}
							// className="op-grid"
							tableBodyMapper={rowMapper}
							expandedColSpan={Object.keys(columns).length}
							RowExpansion={RowExpansion}
							allExpanded={allExpanded}
							SortingComponent={
								(key: string) => (
									<SortingArrows
										sorted={sorting}
										columnKey={key}
										onSort={onSort("sortingCriteria")}
									/>
								)
							}
						/>
					</div>
					<div className="footer-section">
						<PaginationComponent
							totalCount={data.totalCount}
							currentPage={data.currentPage}
							maxCount={userPreferences?.json?.maxPagination!==undefined ? +userPreferences?.json?.maxPagination: maxCount}
							onPageSelect={onPageSelect}
							onSetMaxCount={onSetMaxCount("maxResultCount")}
							onPageBackwards={onPageBackwards}
						/>
					</div>
				</>
			}
		</>
	)
}

export default OportunidadesGrid;

const AdjudicacionesPopover = (dataItem: Oportunidad) => {

	return (
		<OverlayTrigger
			overlay={
				<Popover
					id={"modal-popover"}
					style={{ minWidth: "fit-content" }}
				>
					<PopoverHeader>
						Empresas contratistas
					</PopoverHeader>
					<table className="table-popover">
						<thead>
							<tr>
								<th>Código Empresa</th>
								<th>Nombre Empresa</th>
								<th>Es asfaltera</th>
							</tr>
						</thead>
						<tbody>
							{
								dataItem.adjudicaciones.map(adj => {
									return (
										<tr>
											<td>{adj.contratista.id}</td>
											<td>{adj.contratista.descripcion}</td>
											<td>{ adj.asfaltero==="" || adj.asfaltero===" "  || adj.asfaltero===undefined
												? "No Informado":
												adj.asfaltero
											}</td>
										</tr>
									)
								})
							}
						</tbody>
					</table>
				</Popover>


			}>
			<span>{dataItem.contratistaDescripcion}</span>
		</OverlayTrigger>

	)
}