import { usePsQuery } from "@pavabits/components";
import { useCallback, useContext, useEffect } from "react";
import { useLoading } from "../../../context/Loading.Context";
import { BaseFilter } from "../../../domain/BaseFilter";
import BaseResponse from "../../../domain/BaseResponse";
import { AuthContext } from "../../Login/AuthContextProvider";
import ClienteRepository from "../domain/ClienteRepository";
import Cliente, { ClienteFilter } from "../domain/model/Cliente";
import ClienteApiRepository from "../infraestructure/api/Cliente.ApiRepository";
import { GlobalContext, GlobalContextType } from "../../../context/Global.Context";

function useClientePost(filter: BaseFilter<ClienteFilter>): BaseResponse<Cliente[]> {
	const {getToken, userPreferences} = useContext(AuthContext);
	const {setLoading} = useLoading();
	const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;
	
	const fetchClienteFromApi = useCallback(() => {
		const repo: ClienteRepository = new ClienteApiRepository(getToken());
		setLoading(true);
		filter.sortingCriteria= filter.sortingCriteria == "" ? "lastModificationTime DESC, id" : filter.sortingCriteria;
		if(userPreferences)
			filter.maxResultCount=+userPreferences?.json?.maxPagination;
		return repo.fetchClienteByFilter(filter)
		.finally(() => { 
			setLoading(false)
		});
	}, [filter, getToken]);

	const { data } = usePsQuery(fetchClienteFromApi);

	useEffect(() => {
		updateGlobalState("clientes", {
			pagination: {
				...globalState.clientes?.pagination, 
				currentPage: data?.currentPage,
				totalCount: data?.totalCount,
				maxResultCount: filter?.maxResultCount,
				skipCount: filter?.skipCount
			}})
			}, [data])

	return {
		currentPage: data?.currentPage ?? 0,
		items: data?.items ?? [],
		totalCount: data?.totalCount ?? 0
	}
}

export default useClientePost;
