import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { LibroEstandarDetalleApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import LibroEstandarDetalle from "../../domain/model/LibroEstandarDetalle";

export default class LibroEstandarDetalleRequestGetByLibroEstandarId extends ApiRequest<any, BaseResponse<LibroEstandarDetalle[]>> {
	constructor(id: string, token: string) {
		super(
			"GET",
			LibroEstandarDetalleApiEndpoints.DEFAULT_FILTER + "?Filters[0].Key=libroEstandarId&Filters[0].Value=" + id +"&SortingCriteria=codigounidad",
			{ Authorization: token },
			undefined,
			{}
		)
	}
}