
interface UserPreferenceEntity {
    applicationCod: string,
    applicationName: string,
    component: string,
    description: string,
    json: UserPreferenceJSON
}

export interface UserPreferenceDto{
    applicationCod: string,
    applicationName: string,
    component: string,
    description: string,
    json: string 
};
 

export class UserPreference implements UserPreferenceEntity {
    applicationCod: string;
    applicationName: string;
    component: string;
    description: string;
    json: UserPreferenceJSON;

    constructor(entity : UserPreferenceDto) {
        this.applicationCod=entity.applicationCod;
        this.applicationName=entity.applicationName;
        this.component=entity.component;
        this.description=entity.description;
        this.json=JSON.parse(entity.json)
    }

}   

export class UserPreferenceJSON{
    maxPagination:number
    constructor(maxPagination:number) {
        this.maxPagination=maxPagination
    }

    public toString = (): string=> {
		return JSON.stringify(this);
	};

}


export const USERPREFERENCES_DEFAULT:UserPreferenceEntity=new UserPreference({applicationCod:"COMERCIAL", applicationName:"Comercial",component:"Paginacion", description:"CANTIDAD", json: JSON.stringify( new UserPreferenceJSON(10))});








